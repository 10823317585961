import $ from "jquery";
import _ from "underscore";
import * as Promise from "bluebird";
import AutoNumeric from "autonumeric/dist/autoNumeric.min";
import {verifyPresent, showResult} from "./utils";
import MakePaymentBankTransfer from "./makePaymentBankTransfer";
import MakePaymentGoCardless from "./makePaymentGoCardless";

export default class Payments {
  constructor(app) {
    this.app = app;
    this.app.console.info("Payments");
    
  }
  
  isInGracePeriod() {
    return !_.isEmpty(this.app.gracePeriod) && new Date(this.app.gracePeriod).getTime() > new Date().getTime();
  }
  
  ensureFunds(amount, reason) {
    if (_.isString(amount)) {
      amount = Number(amount.replace(/[^0-9]+/g,""));
    }
    return this.app.postPromise("ensureFunds.php", {"amount": amount}).then(function(result) {
      if (!result.success) {
        this.app.alert(result.message);
        throw result;
      }
      if (result.additionalFundsRequired) {
        return this.makePaymentRequired(result, false, reason);
      }
      return true;
    }.bind(this));
  }
  
  askToPayFunds(amount, reason) {
    if (_.isString(amount)) {
      amount = Number(amount.replace(/[^0-9]+/g,""));
    }
    return this.app.postPromise("askToPayFunds.php", {"amount": amount}).then(function(result) {
      if (!result.success) {
        this.app.alert(result.message);
        throw result;
      }
      return this.makePaymentRequired(result, true, reason);
    }.bind(this));
  }
  
  makePaymentRequired(result, forceAmount, reason) {
    this.forceAmount = forceAmount;
    if (!_.isArray(result.types) || result.types.length == 0) {
      throw new Error("Invalid payment types; no supported types returned.");
    }
    if (result.types.length == 1) {
      return this.makePayment(result.types[0], result, reason);
    }
    return this.choosePaymentMethod(result, reason);
  }
  
  makePayment(mopType, result, reason) {
    if (mopType == "GO_CARDLESS") {
      this.paymentEngine = new MakePaymentGoCardless(this.app);
    } else if (mopType == "BANK_TRANSFER") {
      this.paymentEngine = new MakePaymentBankTransfer(this.app);
    } else {
      throw new Error("Unsupported method of payment type: '" + mopType + "'");
    }
    var amountToPay = result.additionalFundsAmountRequired;
    if (amountToPay % 1000 != 0 && !this.forceAmount)
      amountToPay = amountToPay + (1000 - (amountToPay % 1000));
    return this.paymentEngine.makePaymentAskForConfirmation(amountToPay, reason);
  }
  
  choosePaymentMethod(result, reason) {
    var html = `
    <div class="modal fade" id="choosePaymentDialog" tabindex="-1" role="dialog" aria-labelledby="choosePaymentDialogLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Close</span></button>
            <h4 class="modal-title" id="choosePaymentDialogLabel">Insufficient Funds - Choose A Payment Method</h4>
          </div>
          <div class="modal-body">
             Unfortunately you have insufficient funds to complete this operation, please choose from the below options:
             <div class="form-group">
              <label for="mopType">Method of Payment</label>
              <select class="form-control" id="mopType">
                <option>` +  result.types.join("</option><option>") +  `</option>
              </select>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" id="cancelChoosePayment">Cancel</button>
            <button type="button" class="btn btn-primary" id="confirmChoosePayment">Choose Payment Method</button>
          </div>
        </div>
      </div>
    </div>
    `;
    
    this.dialog = $(html);
    $("body").append(this.dialog);
    this.dialog.modal("show");
    this.paymentSettings = result;
    return new Promise(function(resolve, reject) {
      this.resolve = resolve;
      this.reject  = reject;
      this.dialog.find("#cancelChoosePayment").click( this.choosePaymentCancel .bind(this));
      this.dialog.find("#confirmChoosePayment").click(this.choosePaymentConfirm.bind(this));
      this.dialog.on("hidden.bs.modal", function() {
        this.dialog.remove();
        this.dialog = null;
        this.callReject();
      }.bind(this));
    }).then(function(mopTypeSelected) {
      return this.makePayment(mopTypeSelected, result, reason);
    }.bind(this));
  }
  
  choosePaymentCancel(event) {
    event.preventDefault();
    this.dialog.modal("hide");
    this.callReject();
  }
  
  choosePaymentConfirm(event) {
    event.preventDefault();
    var mopType = $("#mopType").val();
    this.dialog.modal("hide");
    this.resolve(mopType);
  }
  
  callReject() {
    var reject   = this.reject;
    this.reject  = null;
    this.resolve = null;
    if (reject  != null)
      reject(new Error());
  }
  
  callResolve() {
    var resolve  = this.resolve;
    this.reject  = null;
    this.resolve = null;
    if (resolve  != null)
      resolve();
  }   
  
  setupPhpForm() {
    $(document).ready(function() {
      this.howMuchAN = new AutoNumeric("#howMuchToPayIn");
      $("#make-payment-form").submit(this.makePaymentSubmit.bind(this));
    }.bind(this));
  }

  makePaymentSubmit(event) {
    event.preventDefault();
    
    $("#errors *").remove();
    var fail=!verifyPresent("#howMuchToPayIn", "#howMuchToPayDiv");
    
    if (fail) {
      return;
    }
    
    var owed=$("#howMuchOwed").val();
    var desired=Math.floor(parseFloat(this.howMuchAN.getNumericString())*100);
    //Remove check for paying more  if (desired > owed || desired<1 || (desired<1000 && desired!=owed)) {
    if (desired<1 || (desired<1000 && desired!=owed)) {
      showResult(false, $("#howMuchToPayDiv"));
      return;
    }

    
    return this.askToPayFunds(desired, "Manual payment submitted")
      .then(function() {
        $("#make-payment-div").hide();
        $("#current-balance").hide();
        this.app.alert("You request has been successful.");
      }.bind(this))
      .catch(function() {
        this.app.console.info(event);
        $("#btn-make-payment").show();
        $("#errors").append("<div class=\"alert alert-danger alert-dismissible\" role=\"alert\"><button type=\"button\" class=\"close\" data-dismiss=\"alert\"><span aria-hidden=\"true\">&times;</span><span class=\"sr-only\">Close</span></button>Sorry we were unable to register the payment at this time.</div>");
      }.bind(this));
  }
  
}