import $ from "jquery";
import {escapeHtml,verifyPresent,verifyConfirmPassword,resetInput} from "./utils";

export default class UserAdmin {
  constructor(app) {
    this.app = app;
    this.passwordChangeUserId=0;
    this.node=null;
    this.app.console.info("User Admin");
    
    $( document ).ready(function() {
      $(".is-admin-cell").change(this.adminChange.bind(this));
      $(".is-manager-cell").change(this.managerChange.bind(this));
      $(".is-runs-session-cell").change(this.runsSessionChange.bind(this));
      $(".change-password-btn").click(this.showPasswordChange.bind(this));
      $(".get-info-btn").click(this.showGetInfo.bind(this));
      $("#change-password-form").submit(this.changePasswordSubmit.bind(this));
      $("#saveNewPassword").click(this.changePasswordSubmit.bind(this));
      $("#delete-user").click(this.deleteUserSubmit.bind(this));
    }.bind(this));
  }
  
  fnInfoSuccess(responseData) {
    this.node.show();
    var userId=this.user["id"];
    this.node=null;
    
    if (responseData.success!=true) {
      this.app.alert(responseData.message);
    } else {
      this.user["data"] = responseData;
      $("#infoDiv *").remove();
      $("#infoDiv").append("<tr><td>E-Mail Address</td><td>" + escapeHtml($("#user-email-" + userId).val()) + "</td></tr>");
      for (var ix in responseData.data) {
        var elem=responseData.data[ix];
        $("#infoDiv").append("<tr><td>" + escapeHtml(elem[0]) + "</td><td>" + escapeHtml(elem[1]) + "</td></tr>");
      }
      if (responseData.data.length==0) {
        $("#infoDiv").append("<tr><td colspan=\"2\"><div class=\"alert alert-warning\" role=\"alert\">Sorry this user has no info :(</div></td></tr>");
      }
      $("#userInfoDialog").modal("show");
    }
  }
  
  infoFail() {
    this.node.show();
    this.node=null;
  
    this.app.alert("Sorry there was an unexpected error, please try again later.");
  }
  
  adminChange(event) {
    this.app.post("updateUserJSON.php", { "userId": parseInt($(event.target).parent().parent().find(".id-cell").text()), "isAdmin": event.target.checked }, null, null, null);
  }
  
  managerChange(event) {
    this.app.post("updateUserJSON.php", { "userId": parseInt($(event.target).parent().parent().find(".id-cell").text()), "isManager": event.target.checked }, null, null, null);
  }
  
  runsSessionChange(event) {
    this.app.post("updateUserJSON.php", { "userId": parseInt($(event.target).parent().parent().find(".id-cell").text()), "isRunningSessions": event.target.checked }, null, null, null);
  }
  
  sendChangePassword() {
    this.app.post("updateUserJSON.php", { "userId": this.passwordChangeUserId, "password": $("#passIn").val() }, null, null, null);
    $("#passwordChangeDialog").modal("hide");  
    resetInput($("#passIn"), $("#passDiv"));
    resetInput($("#passConfirmIn"), $("#passConfirmDiv"));
  }
    
  changePasswordSubmit(event) {
    event.preventDefault();
    
    $("#errors *").remove();
    
    var fail=!verifyPresent("#passIn", "#passDiv");
    fail|=!verifyConfirmPassword();
      
    if (fail==false) {
      this.sendChangePassword();
    }
  }
  
  showPasswordChange(event) {
    event.preventDefault();
    
    var name = $(event.target).parent().parent().find("td:nth-child(3)").text() + " " + $(event.target).parent().parent().find("td:nth-child(4)").text();
    $("#usersName").text(name);
    this.passwordChangeUserId=parseInt($(event.target).parent().parent().find(".id-cell").text());
    $("#passwordChangeDialog").modal("show");
  }
  
  showGetInfo(event) {
    event.preventDefault();
    
    this.node=$(event.target);
    this.node.hide();
    var parentNode = $(event.target).parent().parent();
    this.user = {
      "id":       parseInt(parentNode.find(".id-cell").text()),
      "forename": parentNode.find(".forename").text(),
      "surname":  parentNode.find(".surname").text()
    };
    this.app.post("getUserInfoJSON.php", { "userId": this.user["id"] }, this.fnInfoSuccess.bind(this), null, this.infoFail.bind(this));
  }
  
  deleteUserSubmit(event) {
    event.preventDefault();
    
    $("#userInfoDialog").modal("hide");
    var message = "Are you sure you want to delete " + this.user["forename"] + " " + this.user["surname"] + " (" + this.user["id"] + ")?";
    this.app.ask("Delete User", message, [
      ["Cancel", "btn-secondary", null],
      ["Ok",     "btn-danger",    this.completeDelete.bind(this)]
    ]);
  }
  
  completeDelete() {
    this.app.post("deleteUserJSON.php", { "userId": this.user["id"] }, this.userDeletedSuccess.bind(this), null, this.userDeletedFail.bind(this));
  }
  
  userDeletedSuccess(responseData) {
    this.app.alert(responseData.message, function() {
      this.app.reloadPage();
    }.bind(this));
  }
  
  userDeletedFail() {
    this.app.alert("Sorry there was an unexpected error deleting this user, please try again later.");
  }
}