import AutoNumeric from "autonumeric/dist/autoNumeric.min";
import {verifyPresent} from "./utils";
import $ from "jquery";




export default class EditScheduledSession {
  constructor(app) {
    this.app = app;

    this.app.console.info("EditScheduledSession");

    $(document).ready(function() {
      this.drivingRebateOverideInAN = new AutoNumeric("#drivingRebateOverideIn");
      $("#drivingRebateOverideForm").submit(this._updateDrivingAmount.bind(this));
      $("#confirmCancelSession").hide();
      $("#cancelSession").click(this.cancelSessionAskConfirm.bind(this));
      $("#confirmCancelSession").click(this.confirmCancelSession.bind(this));
    }.bind(this));
  }
  
  confirmCancelSession(event) {
    event.preventDefault();
    this.app.prompt("Why are you cancelling?", "", function(reason) {
      this.app.post("cancelScheduledSessionJSON.php", { "scheduledSessionId": this.app.scheduledSessionId, "reason": reason }, this.cancelSuccess.bind(this), null, this.cancelFail.bind(this));
    }.bind(this));
  }
  
  cancelSessionAskConfirm(event) {
    event.preventDefault();
    $("#cancelSession").hide();
    $("#confirmCancelSession").show();
  }
  
  cancelSuccess() {
    window.location = "editSession.php?sessionId=" + this.app.sessionId;
  }
  
  cancelFail() {
    this.app.alert("Cancel failed, please try again");
  }
  
  _sendUpdateDrivingAmount() {
    $("#update-rebate-btn").hide();
    $("#errors *").remove();
  
    this.app.post("updateDrivingAmountOverrideJSON.php", 
      { "scheduledSessionId": this.app.scheduledSessionId, "sessionId": this.app.sessionId, "drivingRebateOveride": Math.floor(parseFloat(this.drivingRebateOverideInAN.getNumericString())*100) },
      this._saveSuccess.bind(this), null, this._saveFail.bind(this));
  }
  
  _updateDrivingAmount(event) {
    event.preventDefault();
    
    var fail=!verifyPresent("#drivingRebateOverideIn", "#drivingRebateOverideDiv");
      
    if (fail==false) {
      this._sendUpdateDrivingAmount();
    }
  }
  
  _saveSuccess(responseData) {
    $("#update-rebate-btn").show();
    
    if (responseData.success!=true) {
      $("#errors").append("<div class=\"alert alert-danger alert-dismissible\" role=\"alert\"><button type=\"button\" class=\"close\" data-dismiss=\"alert\"><span aria-hidden=\"true\">&times;</span><span class=\"sr-only\">Close</span></button>" + responseData.message  + ".</div>");
    } else {
      $("#errors").append("<div class=\"alert alert-success alert-dismissible\" role=\"alert\"><button type=\"button\" class=\"close\" data-dismiss=\"alert\"><span aria-hidden=\"true\">&times;</span><span class=\"sr-only\">Close</span></button>Driving amount was changed successfully.</div>");
    }
  }
  
  _saveFail() {
    $("#update-rebate-btn").show();
    $("#errors").append("<div class=\"alert alert-danger alert-dismissible\" role=\"alert\"><button type=\"button\" class=\"close\" data-dismiss=\"alert\"><span aria-hidden=\"true\">&times;</span><span class=\"sr-only\">Close</span></button>Sorry your driving amount was rejected, please try again later.</div>");
  }
}