import $ from "jquery";
import {escapeHtml,verifyPresent} from "./utils";
import AutoNumeric from "autonumeric/dist/autoNumeric.min";




export default class EditSession {
  constructor(app) {
    this.app = app;
    this.srroID = null;
    this.costIx = 0;
    this.app.console.info("Edit Session");
    
    $( document ).ready(function() {
      this.attendanceCostInAN = new AutoNumeric("#attendanceCostIn");
      this.nonAttendanceFineInAN = new AutoNumeric("#nonAttendanceFineIn");
      this.turnedUpInTheDayFineInAN = new AutoNumeric("#turnedUpInTheDayFineIn");
      this.drivingRebateInAN = new AutoNumeric("#drivingRebateIn");
      this.drivingRebateOverideInAN = new AutoNumeric("#drivingRebateOverideIn");  
      //this.sessionCostsCostInAN = new AutoNumeric("#sessionCostsCostIn");
      this.sessionCostsCostInIxAN = [];
      $("#session-form").submit(this.saveSessionSubmit.bind(this));
      if ($("#sessionId").val() == "") {
        $("#deleteSession").hide();
      }
      $("#confirmDeleteSession").hide();
      $("#deleteSession").click(this.deleteSessionAskConfirm.bind(this));
      $("#confirmDeleteSession").click(this.confirmDeleteSession.bind(this));
      $(".session-registration").change(this._sessionRegistrationChange.bind(this));
      $("#addScheduledSession").click(this._addScheduledSession.bind(this));
      $("#add-sched-session-form").submit(this._saveScheduledSession.bind(this));
      $("#saveManualSession").click(this._saveScheduledSession.bind(this));
      $("#addOrCondition").click(this._addOrCondition.bind(this));
      $("#addCostField").click(this._addCostField.bind(this));
      
      for (var orCondition in this.app.ors) {
        $("#deleteOrCondition-" + this.app.ors[orCondition]).click(this.app.ors[orCondition], this._deleteOrCondition.bind(this));
      }
      for (var ix in this.app.costs) {
        this._addCost(this.app.costs[ix]);
      }
    }.bind(this));
  }
  
  //found here: https://gist.github.com/ryanburnette/8803238
  setNow(node, onlyBlank) {
    var now = new Date($.now());
    
    var year = now.getFullYear();
    
    var month = (now.getMonth() + 1).toString().length === 1 ? "0" + (now.getMonth() + 1).toString() : now.getMonth() + 1;
    var date = now.getDate().toString().length === 1 ? "0"         + (now.getDate()).toString()      : now.getDate();
    var hours = now.getHours().toString().length === 1 ? "0"       + now.getHours().toString()       : now.getHours();
    var minutes = now.getMinutes().toString().length === 1 ? "0"   + now.getMinutes().toString()     : now.getMinutes();
    var seconds = now.getSeconds().toString().length === 1 ? "0"   + now.getSeconds().toString()     : now.getSeconds();
    
    var formattedDateTime = year + "-" + month + "-" + date + "T" + hours + ":" + minutes + ":" + seconds;
  
    if (onlyBlank === true && node.val()) {
      return;
    }
    
    node.val(formattedDateTime);
  }
  
  //Session Stuff
  _addCostField(event) {
    event.preventDefault();
    this._addCost({"sessionCostId":null,"description":"","cost":0,"sessionId":$("#sessionId").val()});
  }
  
  _addCost(elem) {
    this.costIx++;
    var cmdIx=this.costIx;
    $("#sessionCostsParent").append("<div class=\"session-cost\" id=\"sessionCostsDiv" + this.costIx + "\">"+
        "<div class=\"form-group sessionCostsDescriptionDiv\" id=\"sessionCostsDescriptionDiv" + this.costIx + "\"><label for=\"sessionCostsDescriptionIn" + this.costIx + "\">Description </label><input type=\"text\" class=\"form-control sessionCostsDescription\" id=\"sessionCostsDescriptionIn" + this.costIx + "\" placeholder=\"Enter label (e.g. rink hire)\"></div>"+
        " <div class=\"form-group sessionCostsCostDiv\" id=\"sessionCostsCostDiv" + this.costIx + "\"><label for=\"sessionCostsCostIn" + this.costIx + "\"> Costs </label><input class=\"sessionCostsCost\" type=\"text\" data-a-sign=\"£ \" id=\"sessionCostsCostIn" + this.costIx + "\"></input></div>"+
        " <button type=\"button\" class=\"btn btn-danger\" id=\"sessionCostsDel" + this.costIx + "\"><span class=\"fas fa-minus\"></span> Del</button>  "+
        "</div>");
    
                  
    $("#sessionCostsDescriptionIn" + this.costIx).val(elem.description);
    this.sessionCostsCostInIxAN[this.costIx] = new AutoNumeric("#sessionCostsCostIn" + this.costIx);
    this.sessionCostsCostInIxAN[this.costIx].set(elem.cost/100);
    $("#sessionCostsDel" + this.costIx).click(function() {
      $("#sessionCostsDiv" + cmdIx).remove();
    }.bind(this));
  }
  
  sessionSuccess(responseData) {
    $("#labelIn").removeAttr("disabled");
    $("#descriptionIn").removeAttr("disabled");
    $("#attendanceCostIn").removeAttr("disabled");
    $("#nonAttendanceFineIn").removeAttr("disabled");
    $("#turnedUpInTheDayFineIn").removeAttr("disabled");
    $("#drivingRebateIn").removeAttr("disabled");
    $("#sessionCostsCostIn").removeAttr("disabled");
    $("#sessionCostsDescriptionIn").removeAttr("disabled");
    $("#disallowRegistrationHowLogBeforeIn").removeAttr("disabled");
    
    if (responseData.success!=true) {
      this.app.alert(responseData.message);
    } else {
      if ($("#sessionId").val() == "") {
        window.location = "sessionAdmin.php";    
      } else {
        this.app.reloadPage();
      }
    }
  }
  
  sessionFail() {
    $("#labelIn").removeAttr("disabled");
    $("#descriptionIn").removeAttr("disabled");
    $("#attendanceCostIn").removeAttr("disabled");
    $("#nonAttendanceFineIn").removeAttr("disabled");
    $("#turnedUpInTheDayFineIn").removeAttr("disabled");
    $("#drivingRebateIn").removeAttr("disabled");
    $("#sessionCostsCostIn").removeAttr("disabled");
    $("#sessionCostsDescriptionIn").removeAttr("disabled");
    $("#categoryIn").removeAttr("disabled");
    $("#disallowRegistrationHowLogBeforeIn").removeAttr("disabled");
    this.app.alert("Save failed, please try again");
  }
  
  sendSaveUpdateSession() {
    $("#labelIn").attr("disabled", "disabled");
    $("#descriptionIn").attr("disabled", "disabled");
    $("#attendanceCostIn").attr("disabled", "disabled");
    $("#nonAttendanceFineIn").attr("disabled", "disabled");
    $("#turnedUpInTheDayFineIn").attr("disabled", "disabled");
    $("#drivingRebateIn").attr("disabled", "disabled");
    $("#sessionCostsCostIn").attr("disabled", "disabled");
    $("#sessionCostsDescriptionIn").attr("disabled", "disabled");
    $("#categoryIn").attr("disabled", "disabled");
    $("#disallowRegistrationHowLogBeforeIn").attr("disabled", "disabled");
    var data={ 
      "label": $("#labelIn").val(), 
      "description": $("#descriptionIn").val(), 
      "attendanceCost": Math.floor(parseFloat(this.attendanceCostInAN.getNumericString())*100), 
      "nonAttendanceFine": Math.floor(parseFloat(this.nonAttendanceFineInAN.getNumericString())*100), 
      "turnedUpInTheDayFine": Math.floor(parseFloat(this.turnedUpInTheDayFineInAN.getNumericString())*100), 
      "drivingRebate": Math.floor(parseFloat(this.drivingRebateInAN.getNumericString())*100), 
      "disallowRegistrationHowLogBefore": parseInt($("#disallowRegistrationHowLogBeforeIn").val()), 
      "categoryId": parseInt($("#categoryIn").val()), 
      "sessionId": $("#sessionId").val(),
      "costs": []
    };
    for (var i = 1; i<=this.costIx; i++) {
      if ($("#sessionCostsDiv" + i).length > 0) {
        data["costs"].push({
          "description": $("#sessionCostsDescriptionIn" + i).val(), 
          "cost": Math.floor(parseFloat(this.sessionCostsCostInIxAN[i].getNumericString())*100)
        });
      }
    }
    this.app.post("upsertSessionJSON.php", data, this.sessionSuccess.bind(this), null, this.sessionFail.bind(this));
  }
  
  saveSessionSubmit(event) {
    event.preventDefault();
    
    var fail=!verifyPresent("#labelIn", "#labelDiv");
    fail|=!verifyPresent("#descriptionIn", "#descriptionDiv");
    fail|=!verifyPresent("#attendanceCostIn", "#attendanceCostDiv");
    fail|=!verifyPresent("#nonAttendanceFineIn", "#nonAttendanceFineDiv");
    fail|=!verifyPresent("#turnedUpInTheDayFineIn", "#turnedUpInTheDayFineDiv");
    fail|=!verifyPresent("#drivingRebateIn", "#drivingRebateDiv");
    fail|=!verifyPresent("#categoryIn", "#categoryDiv");
    fail|=!verifyPresent("#disallowRegistrationHowLogBeforeIn", "#disallowRegistrationHowLogBeforeDiv");
    for (var i = 1; i<=this.costIx; i++) {
      if ($("#sessionCostsDiv" + i).length > 0) {
        fail|=!verifyPresent("#sessionCostsDescriptionIn" + i, "#sessionCostsDescriptionDiv" + i);
        fail|=!verifyPresent("#sessionCostsCostIn" + i, "#sessionCostsCostDiv" + i);
      }
    }
      
    if (fail==false) {
      this.sendSaveUpdateSession();
    }
  }
  
  confirmDeleteSession(event) {
    event.preventDefault();
    this.app.post("deleteSessionJSON.php", { "sessionId": $("#sessionId").val()  }, this.deleteSuccess.bind(this), null, this.deleteFail.bind(this));
  }
  
  deleteSessionAskConfirm() {
    $("#deleteSession").hide();
    $("#confirmDeleteSession").show();
  }
  
  deleteSuccess(responseData) {
    if (responseData.success!=true) {
      this.app.alert(responseData.message);
    } else {
      window.location = "sessionAdmin.php";
    }
  }
  
  deleteFail() {
    this.app.alert("Delete failed, please try again");
  }
  
  _sessionRegistrationChange(event) {
    var isChecked=0;
    if (event.target.checked)
      isChecked=1;
    var parts=$(event.target).attr("id").split("-");
    this.srroID=parseInt(parts[1]);
    var regId=parseInt(parts[2]);
    
    this.app.post("sessionRequireRegJSON.php", { "srroID": this.srroID, "regId": regId, "isChecked": isChecked }, null, null, null);
  }
  
  _addScheduledSession(event) {
    event.preventDefault();
    
    this.drivingRebateOverideInAN.set(this.drivingRebateInAN.getNumericString());
    this.setNow($("#whenItIsIn"));
    $("#addManualSessionDialog").modal("show");
  }
  
  _saveScheduledSession(event) {
    event.preventDefault();
    
    var fail=!verifyPresent("#whenItIsIn", "#whenItIsDiv");
    fail|=!verifyPresent("#drivingRebateOverideIn", "#drivingRebateOverideDiv");
  
    if (fail==false) {
      this._sendAddScheduledSession();
    }
  }
  
  _sendAddScheduledSession() {
    $("#saveManualSession").hide();
    $("#errors *").remove();
    var sessionId=parseInt($("#sessionId").val());
    
    this.app.post("createManualSessionJSON.php", 
      { "sessionId": sessionId, "whenItIsIn": $("#whenItIsIn").val(), "drivingRebateOveride": Math.floor(parseFloat(this.drivingRebateOverideInAN.getNumericString())*100) }, 
      this._saveScheduledSessionSuccess.bind(this), null, this._saveScheduledSessionFail.bind(this));
  }
  
  _saveScheduledSessionSuccess(responseData) {
    $("#saveManualSession").show();
    
    if (responseData.success!=true) {
      $("#errors").append("<div class=\"alert alert-danger alert-dismissible\" role=\"alert\"><button type=\"button\" class=\"close\" data-dismiss=\"alert\"><span aria-hidden=\"true\">&times;</span><span class=\"sr-only\">Close</span></button>" + responseData.message  + ".</div>");
    } else {
      this.app.reloadPage();
    }
  }
  
  _saveScheduledSessionFail() {
    $("#saveManualSession").show();
    $("#errors").append("<div class=\"alert alert-danger alert-dismissible\" role=\"alert\"><button type=\"button\" class=\"close\" data-dismiss=\"alert\"><span aria-hidden=\"true\">&times;</span><span class=\"sr-only\">Close</span></button>Sorry your session was rejected, please try again later.</div>");
  }
  
  _addOrCondition(event) {
    event.preventDefault();
    $("#addOrCondition").hide();
    var sessionId=parseInt($("#sessionId").val());
    
    this.app.post("sessionRequireRegOrJSON.php", { "sessionId": sessionId }, this._addOrConditionSuccess.bind(this), null, this._addOrConditionFail.bind(this));
  }
  
  _addOrConditionSuccess(responseData) {
    $("#addOrCondition").show();
    
    if (responseData.success!=true) {
      this.app.alert(responseData.message);
    } else {
      var html="<td style=\"vertical-align: bottom;\" id=\"orCell-" + responseData.srroID + "\">";
      var regIx, reg;
      for (regIx in this.app.regs) {
        reg=this.app.regs[regIx];
        html += "<div class=\"checkbox\"><label><input type=\"checkbox\" class=\"session-registration\" id=\"registrations-" + responseData.srroID + "-" + reg[0] + "\"> " + escapeHtml(reg[1]) + "</label></div>";
      }
    
      html+="<a type=\"button\" class=\"btn btn-primary\" id=\"deleteOrCondition-" + responseData.srroID + "\" href=\"#\">Delete Or Condition</a>";
      html+="</td>";
      $("#orAddCell").before(html);
      $("#deleteOrCondition-" + responseData.srroID).click(responseData.srroID, this._deleteOrCondition.bind(this));
      for (regIx in this.app.regs) {
        reg=this.app.regs[regIx];
        $("#registrations-" + responseData.srroID + "-" + reg[0]).change(this._sessionRegistrationChange.bind(this));
      }
    }
  }
  
  _addOrConditionFail() {
    $("#addOrCondition").show();
    this.app.alert("Create failed, please try again");
  }
  
  
  _deleteOrCondition(event) {
    event.preventDefault();
    this.srroID=event.data;
    $("#deleteOrCondition-"+this.srroID).hide();
    this.app.post("sessionRequireRegOrJSON.php", { "srroID": this.srroID }, this._deleteOrConditionSuccess.bind(this), null, this._deleteOrConditionFail.bind(this));
  }
  
  
  _deleteOrConditionSuccess(responseData) {
    $("#deleteOrCondition-"+this.srroID).show();
    
    if (responseData.success!=true) {
      this.app.alert(responseData.message);
    } else {
      $("#orCell-"+this.srroID).remove();
    }
  }
  
  _deleteOrConditionFail() {
    $("#deleteOrCondition-"+this.srroID).show();
    this.app.alert("Create failed, please try again");
  }
}