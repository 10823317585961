import AutoNumeric from "autonumeric/dist/autoNumeric.min";
import {verifyPresent,verifyRegex} from "./utils";
import $ from "jquery";
import _ from "underscore";




export default class RegistrationsAdmin {
  constructor(app) {
    this.registrationId="";
    this.fieldIx=0;
    this.costIx=0;
    this.app = app;
    this.app.console.info("Registrations Admin");
    
    $( document ).ready(function() {
      this.registrationCostAmountInIxAn = [];
      $("#addRegistration").bind(this).click(this.addRegistration.bind(this));
      $("#saveRegistration").bind(this).click(this.saveRegistrationSubmit.bind(this));
      $("#addedit-registration-form").bind(this).submit(this.saveRegistrationSubmit.bind(this));
      $(".edit-registration-btn").bind(this).click(this.showRegistrationEdit.bind(this));
      $("#deleteRegistration").bind(this).click(this.deleteRegistrationAskConfirm.bind(this));
      $("#confirmDeleteRegistration").bind(this).click(this.confirmDeleteRegistration.bind(this));
      $("#addOptionalField").bind(this).click(this._addOptionalField.bind(this));
      $("#addCostField").bind(this).click(this._addCostField.bind(this));
    }.bind(this));
  }
   
  disableEnable(disableNow) {
    var elems = ["#labelIn","#descriptionIn","#registrationCostIn","expiryDayIn","#expiryMonthIn","#registrationEnabled"];
    _.each(elems, function(elem) {
      if (disableNow) {
        $(elem).attr("disabled", "disabled");
      } else {
        $(elem).removeAttr("disabled");    
      }
    }, this);
  }
  
  registerSuccess(responseData) {
    this.disableEnable(false);
    if (responseData.success!=true) {
      this.app.alert(responseData.message);
    } else {
      this.app.reloadPage();
    }
  }
  
  registerFail() {
    this.disableEnable(false);
    this.app.alert("Save failed, please try again");
  }
  
  sendSaveUpdateRegistration() {
    this.disableEnable(true);
    var data={
      "label": $("#labelIn").val(),
      "description": $("#descriptionIn").val(),
      "expiryDay": $("#expiryDayIn").val(),
      "expiryMonth": $("#expiryMonthIn").val(),
      "regId": this.registrationId,
      "optionalFields": [],
      "costs": [],
      "enabled": $("#registrationEnabled").prop("checked")
    };
    $(".optionalFieldDiv").each(function() {
      data.optionalFields.push(
        {"key": $(this).find(".optionalFieldKey").val(), 
          "label": $(this).find(".optionalFieldLabel").val(), 
          "regex": $(this).find(".optionalFieldRegex").val()});
    });
    $(".registrationCostDiv").each(function(index, element) {
      element = $(element);
      var costIxLcl = element.attr("id").substr("registrationCostDiv".length);
      data.costs.push(
        {"cost": Math.floor(parseFloat(this.registrationCostAmountInIxAn[costIxLcl].getNumericString())*100), 
          "label": $(element).find(".registrationCostLabel").val()});
    }.bind(this));
    this.app.post("upsertRegistrationJSON.php", data, this.registerSuccess.bind(this), null, this.registerFail.bind(this));
  }
  
  saveRegistrationSubmit(event) {
    event.preventDefault();
  
    var fail=!verifyPresent("#labelIn", "#labelDiv");
    fail|=!verifyPresent("#descriptionIn", "#descriptionDiv");
    $(".optionalFieldDiv").each(function() {
      fail|=!verifyPresent("#"+$(this).find(".optionalFieldKey").attr("id"), "#"+$(this).find(".optionalFieldKeyDiv").attr("id"));
      fail|=!verifyPresent("#"+$(this).find(".optionalFieldLabel").attr("id"), "#"+$(this).find(".optionalFieldLabelDiv").attr("id"));
      fail|=!verifyRegex("#"+$(this).find(".optionalFieldRegex").attr("id"), "#"+$(this).find(".optionalFieldRegexDiv").attr("id"));
    });
    $(".registrationCostDiv").each(function() {
      fail|=!verifyPresent("#"+$(this).find(".registrationCostAmount").attr("id"), "#"+$(this).find(".registrationCostAmountDiv").attr("id"));
      fail|=!verifyPresent("#"+$(this).find(".registrationCostLabel").attr("id"), "#"+$(this).find(".registrationCostLabelDiv").attr("id"));
    });
  
    if (fail==false) {
      this.sendSaveUpdateRegistration();
    }
  }
  
  addRegistration(event) {
    event.preventDefault();
  
    $("#deleteRegistration").hide();
    $("#confirmDeleteRegistration").hide();
  
    this.registrationId="";
    $("#labelIn").val("");
    $("#descriptionIn").val("");
    $("#registrationCostIn").val("");
    $("#expiryDayIn").val("1");
    $("#expiryMonthIn").val("1");
    $("#registrationEnabled").prop("checked", true);
    $("#optionalFields *").remove();
    $("#addEditRegistrationDialog").modal("show");
  }
  
  showRegistrationEdit(event) {
    event.preventDefault();
  
    $("#deleteRegistration").show();
    $("#confirmDeleteRegistration").hide();
    $("#optionalFields *").remove();
    $("#registrationCosts *").remove();
    
    this.registrationId=parseInt($(event.target).parent().parent().find(".id-cell").text());
    var reg=[];
    for (var ix in this.app.registrationCache) {
      if (this.app.registrationCache[ix]["regId"]==this.registrationId) {
        reg=this.app.registrationCache[ix];
        for (ix in reg.optionalFields) {
          this._addField(reg.optionalFields[ix]);
        }
        for (ix in reg.costs) {
          this._addCost(reg.costs[ix]);
        }
      }
    }
    $("#labelIn").val(reg["label"]);
    $("#descriptionIn").val(reg["description"]);
    $("#expiryDayIn").val(reg["expiryDay"]);
    $("#expiryMonthIn").val(reg["expiryMonth"]);
    $("#registrationEnabled").prop("checked", reg["enabled"]);
    $("#addEditRegistrationDialog").modal("show");
  }
  
  _addCost(elem) {
    this.costIx++;
    var cmdIx=this.costIx;
    $("#registrationCosts").append("<div class=\"row registrationCostDiv\" id=\"registrationCostDiv" + this.costIx + "\"><div class=\"col-md-5\">"+
                  "<div class=\"form-group registrationCostAmountDiv\" id=\"registrationCostAmountDiv" + this.costIx + "\"><label for=\"registrationCostAmountIn" + this.costIx + "\">Registration Costs</label><input class=\"registrationCostAmount\" type=\"text\" data-a-sign=\"£ \" id=\"registrationCostAmountIn" + this.costIx + "\"></input></div>"+
                  "</div><div class=\"col-md-5\">"+
                  "<div class=\"form-group registrationCostLabelDiv\" id=\"registrationCostLabelDiv" + this.costIx + "\"><label for=\"registrationCostLabelIn" + this.costIx + "\">Label</label><input type=\"text\" class=\"form-control registrationCostLabel\" id=\"registrationCostLabelIn" + this.costIx + "\" placeholder=\"Label for the field\"></div>"+
                  "</div><div class=\"col-md-2\">"+
                  "&nbsp;<br /><button type=\"button\" class=\"btn btn-danger\" id=\"registrationCostDel" + this.costIx + "\"><span class=\"fas fa--minus\"></span> Del</button>  "+
                  "</div></div>");
                  
    $("#registrationCostLabelIn" + this.costIx).val(elem.label);
    this.registrationCostAmountInIxAn[this.costIx] = new AutoNumeric("#registrationCostAmountIn" + this.costIx);
    this.registrationCostAmountInIxAn[this.costIx].set(elem.cost/100);
    $("#registrationCostDel" + this.costIx).click(function() {
      $("#registrationCostDiv" + cmdIx).remove();
    }.bind(this));
  }
  
  _addField(elem) {
    this.fieldIx++;
    var cmdIx=this.fieldIx;
    $("#optionalFields").append("<div class=\"row optionalFieldDiv\" id=\"optionalFieldDiv" + this.fieldIx + "\"><div class=\"col-md-4\">"+
                  "<div class=\"form-group optionalFieldKeyDiv\" id=\"optionalFieldKeyDiv" + this.fieldIx + "\"><label for=\"optionalFieldKeyIn" + this.fieldIx + "\">Key</label><input type=\"text\" class=\"form-control optionalFieldKey\" id=\"optionalFieldKeyIn" + this.fieldIx + "\" placeholder=\"Key e.g. TELEPHONE\"></div>"+
                  "</div><div class=\"col-md-3\">"+
                  "<div class=\"form-group optionalFieldLabelDiv\" id=\"optionalFieldLabelDiv" + this.fieldIx + "\"><label for=\"optionalFieldLabelIn" + this.fieldIx + "\">Label</label><input type=\"text\" class=\"form-control optionalFieldLabel\" id=\"optionalFieldLabelIn" + this.fieldIx + "\" placeholder=\"Label for the field\"></div>"+
                  "</div><div class=\"col-md-3\">"+
                  "<div class=\"form-group optionalFieldRegexDiv\" id=\"optionalFieldRegexDiv" + this.fieldIx + "\"><label for=\"optionalFieldRegexIn" + this.fieldIx + "\">Validation Regex</label><input type=\"text\" class=\"form-control optionalFieldRegex\" id=\"optionalFieldRegexIn" + this.fieldIx + "\" placeholder=\"regex e.g. ^[a-z]*$\"></div>"+
                  "</div><div class=\"col-md-2\">"+
                  "&nbsp;<br /><button type=\"button\" class=\"btn btn-danger\" id=\"optionalFieldDel" + this.fieldIx + "\"><span class=\"fas fa--minus\"></span> Del</button>  "+
                  "</div></div>");
                  
    $("#optionalFieldKeyIn" + this.fieldIx).val(elem.infoKey);
    $("#optionalFieldLabelIn" + this.fieldIx).val(elem.label);
    $("#optionalFieldRegexIn" + this.fieldIx).val(elem.regex);
    $("#optionalFieldDel" + this.fieldIx).click(function() {
      $("#optionalFieldDiv" + cmdIx).remove();
    }.bind(this));
  }
  
  _addCostField(event) {
    event.preventDefault();
    this._addCost({"regCostId":null,"regId":this.registrationId,"cost":0,"label":""});
  }
  
  _addOptionalField(event) {
    event.preventDefault();
    this._addField({"rriId":null,"regId":this.registrationId,"infoKey":"","label":"","regex":""});
  }
  
  confirmDeleteRegistration(event) {
    event.preventDefault();
    this.app.post("deleteRegistrationJSON.php", { "regId": this.registrationId }, this.deleteSuccess.bind(this), null, this.deleteFail.bind(this));
  }
  
  deleteRegistrationAskConfirm() {
    event.preventDefault();
    $("#deleteRegistration").hide();
    $("#confirmDeleteRegistration").show();
  }
  
  
  deleteSuccess(responseData) {
    if (responseData.success!=true) {
      this.app.alert(responseData.message);
    } else {
      this.app.reloadPage();
    }
  }
  
  deleteFail() {
    this.app.alert("Delete failed, please try again");
  }
}