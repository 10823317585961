import $ from "jquery";




export default class ReviewDeposits {
  constructor(app) {
    this.app = app;
    this.app.console.info("Review Deposits");

    $( document ).ready(function() {
      $(".confirm-btn").click(this.confirm.bind(this));
      $(".reject-btn").click(this.reject.bind(this));
    }.bind(this));
  }
      
  success(id, node, retval) {
    if (retval.success) {
      $(node).parent().parent().remove();
    } else {
      $(node).parent().parent().find("button").show();
    }
  }
  
  fail(id, node) {
    $(node).parent().parent().find("button").show();
  }
  
  confirm(event) {
    this.confirmReject(event, true);
  }
  
  reject(event) {
    this.confirmReject(event, false);
  }
  
  confirmReject(event,confirmReject) {
    event.preventDefault();
    $(event.target).parent().parent().find("button").hide();
    
    var id = parseInt($(event.target).parent().parent().find("td:nth-child(1)").text());
    this.app.post("reviewDepositsJSON.php", { "id": id, "confirm": confirmReject },
      this.success.bind(this, id, event.target), null,
      this.fail.bind(this, id, event.target));
  }
}