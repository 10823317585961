import $ from "jquery";
import {escapeHtml} from "./utils";




export default class SessionRegister {
  constructor(app) {
    this.app = app;
    this.app.console.info("Session Register");
    
    $( document ).ready(function() {
      $(".scheduled-session").click(this.openRegister.bind(this));
      $("#cancelWholeSession").hide();
      $("#cancelWholeSession").click(this.cancelWholeSession.bind(this));
      $("#markReviewed").click(this.markReviewed.bind(this));
      $("#markReviewed").hide();
      $("#confirmCancelSession").click(this.confirmCancelSession.bind(this));
    }.bind(this));
  }
      
  attendSuccess(data, attend, late, response) {
    var id=data[3];
    if (response.success) {
      if (attend) {
        $("#not-attended-"+id).show();
        $("#attendee-"+id).addClass("attending");
        $("#attendee-"+id).removeClass("not-attending");
      } else {
        $("#attended-"+id).show();
        $("#attendee-"+id).addClass("not-attending");
        $("#attendee-"+id).removeClass("attending");
      }
      if (late) {
        $("#attendee-"+id).addClass("late");
      } else {
        $("#attendee-"+id).removeClass("late");
      }
      $("#attendee-"+id).removeClass("removed");
    } else {
      this.attendFail(data, attend, response);
    }
  }
  
  attendFail(data, attend) {
    var id=data[3];
    if (attend)
      $("#attended-"+id).show();
    else 
      $("#not-attended-"+id).show();
  }
  
  removeSuccess(data, attend, response) {
    var id=data[3];
    if (response.success) {
      $("#user-row-"+id).remove();
    } else {
      this.attendFail(data, attend, response);
    }
  }
  
  removeFail(data) {
    var id=data[3];
    $("#remove-"+id).show();
  }
  
  driveSuccess(data, attend, response) {
    var id=data[3];
    if (response.success) {
      if (attend) {
        $("#not-drove-"+id).show();
      } else {
        $("#drove-"+id).show();
      }
    } else {
      this.attendFail(data, attend, response);
    }
  }
  
  driveFail(data, attend) {
    var id=data[3];
    if (attend)
      $("#drove-"+id).show();
    else 
      $("#not-drove-"+id).show();
  }
  
  attend(event) {
    var id=event.data[3];
    $("#attended-"+id).hide();
    $("#not-attended-"+id).hide();
    
    this.app.post("sessionRegisterAttendJSON.php", { "usersSessionId": id, "isAttended": true }, 
      this.attendSuccess.bind(this, event.data, true, false), null, 
      this.attendFail.bind(this, event.data, true));
  }
  
  _remove(event) {
    var id=event.data[3];
    $("#remove-"+id).hide();
  
    this.app.post("sessionRegisterRemoveJSON.php", { "usersSessionId": id }, 
      this.removeSuccess.bind(this, event.data, true), null, 
      this.removeFail.bind(this, event.data, true));
  }
  
  notAttend(event) {
    var id=event.data[3];
    $("#attended-"+id).hide();
    $("#not-attended-"+id).hide();
    
    this.app.post("sessionRegisterAttendJSON.php", { "usersSessionId": id, "isAttended": false }, 
      this.attendSuccess.bind(this, event.data, false, false), null, 
      this.attendFail.bind(this, event.data, false));
  }
  
  drove(event) {
    var id=event.data[3];
    $("#drove-"+id).hide();
    $("#not-drove-"+id).hide();
    
    this.app.post("sessionRegisterDriveJSON.php", { "usersSessionId": id, "isDriving": true }, 
      this.driveSuccess.bind(this, event.data, true), null, 
      this.driveFail.bind(this, event.data, true));
  }
  
  notDrove(event) {
    var id=event.data[3];
    $("#drove-"+id).hide();
    $("#not-drove-"+id).hide();
    
    this.app.post("sessionRegisterDriveJSON.php", { "usersSessionId": id, "isDriving": false }, 
      this.driveSuccess.bind(this, event.data, false), null, 
      this.driveFail.bind(this, event.data, false));
  }
  
  loadSessionSuccess(responseData) {
    $("#btn-make-payment").show();
    
    if (responseData.success!=true) {
      $("#pick-table").show();
      $("#pick-session").show();
      $("#errors").append("<div class=\"alert alert-danger alert-dismissible\" role=\"alert\"><button type=\"button\" class=\"close\" data-dismiss=\"alert\"><span aria-hidden=\"true\">&times;</span><span class=\"sr-only\">Close</span></button>" + responseData.message + ".</div>");    
    } else {
      var data=responseData.data;
      if (data.length==1 && data[0][3]==null) {
        $("#pick-table").show();
        $("#pick-session").show();
        $("#errors").append("<div class=\"alert alert-danger alert-dismissible\" role=\"alert\"><button type=\"button\" class=\"close\" data-dismiss=\"alert\"><span aria-hidden=\"true\">&times;</span><span class=\"sr-only\">Close</span></button> Sorry nobody has yet signed up to this session.</div>");    
        return;
      }
      
      var tbl=$("#user-table");
      for (var ix in data) {
        var id=data[ix][3];
        var html="<tr id=\"user-row-" + id + "\">"+
              "<td style=\"padding: 0px\">"+
              "<table class=\"table\" style=\"padding: 0px; margin: 0px;\">"+
                "<tr>"+
                "<td class=\"tbl-attendee\" id=\"attendee-"+id+"\">"+escapeHtml(data[ix][13])+" "+escapeHtml(data[ix][14])+"</td>";
        if (this.app.isAdmin) {
          html+="<td class=\"tbl-button attended\" id=\"remove-"+id+"\">Remove</td>";
        }
        html+=      "<td class=\"tbl-button attended\" id=\"attended-"+id+"\">Attended</td>"+
                "<td class=\"tbl-button not-attended\" id=\"not-attended-"+id+"\">Missed</td>"+
                "<td class=\"tbl-button drove\" id=\"drove-"+id+"\">Drove</td>"+
                "<td class=\"tbl-button not-drove\" id=\"not-drove-"+id+"\">Didn't Drive</td>"+
              "</tr></table>"+
              "</td></tr>";
        tbl.append(html);
        if (data[ix][11]==true) {  
          $("#drove-"+id).hide();
        } else {
          $("#not-drove-"+id).hide();
        }        
        if (data[ix][12]!=null) {
          if (data[ix][12]) {  
            $("#attended-"+id).hide();
            $("#attendee-"+id).addClass("attending");
          } else {
            $("#not-attended-"+id).hide();
            $("#attendee-"+id).addClass("not-attending");
          }      
          if (data[ix][8]!=null) {    
            $("#attendee-"+id).addClass("late");
          }
          if (data[ix][15]==1) {    
            $("#attendee-"+id).addClass("removed");
          }
        } else if (data[ix][8]!=null) {    
          $("#attendee-"+id).addClass("late");
        }
        if (this.app.isAdmin) {
          $("#remove-"+id).click(data[ix], this._remove.bind(this));
        }
        $("#attended-"+id).click(data[ix], this.attend.bind(this));
        $("#not-attended-"+id).click(data[ix], this.notAttend.bind(this));
        $("#drove-"+id).click(data[ix], this.drove.bind(this));
        $("#not-drove-"+id).click(data[ix], this.notDrove.bind(this));
      }    
      tbl.show();
      $("#cancelWholeSession").show();
      $("#markReviewed").show();
    }
  }
  
  loadSessionFail() {
    $("#pick-table").show();
    $("#pick-session").show();
    this.app.alert("Sorry we were unable to load the session at this time");
  }
  
  sendLoadSession(desired) {
    $("#btn-make-payment").hide();
    this.app.post("loadSessionJSON.php", { "amount": desired }, this.loadSessionSuccess.bind(this), null, this.loadSessionFail.bind(this));
  }
  
  openRegister(event) {
    event.preventDefault();
    
    var scheduledSessionId=$(event.target).parent().attr("id").substring(19);
    if ($(event.target).parent().hasClass("cancelled")) {
      return;
    }
    this.openRegisterWithId(scheduledSessionId);
  }
  
  markReviewed(e) {
    e.preventDefault();
    $("#markReviewed").hide();
  
    this.app.post("markReviewedJSON.php", { "scheduledSessionId": $("#scheduledSessionId").val() }, this.markReviewedSuccess.bind(this), null, this.markReviewedFail.bind(this));
  }
  
  markReviewedSuccess(responseData) {
    this.app.alert(responseData.message);
  }
  
  markReviewedFail() {
    this.app.alert("Mark as reviewed failed, please try again later");
  }
  
  cancelWholeSession(e) {
    e.preventDefault();
    $("#cancelSessionConfirmDialog").modal("show");
  }
  
  confirmCancelSession(e) {
    e.preventDefault();
    $("#cancelSessionConfirmDialog").modal("hide");
    this.app.prompt("Why are you cancelling?", "",
      function(reason) {
        this.app.post("cancelScheduledSessionJSON.php", { "scheduledSessionId": $("#scheduledSessionId").val(), "reason": reason }, this.cancelSuccess.bind(this), null, this.cancelFail.bind(this));
      }.bind(this)
    );
  }
  
  cancelSuccess() {
    window.location = this.app.siteBase + "/src/php/bookings/sessionRegister.php";
  }
  
  cancelFail() {
    this.app.alert("Cancel failed, please try again");
  }
  
  openRegisterWithId(scheduledSessionId) {
    $("#pick-table").hide();
    $("#pick-session").hide();
    $("#errors *").remove();
    $("#scheduledSessionId").val(scheduledSessionId);
    this.app.post("loadSessionJSON.php", { "scheduledSessionId": scheduledSessionId }, this.loadSessionSuccess.bind(this), null, this.loadSessionFail.bind(this));
  }
}