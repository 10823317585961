import AutoNumeric from "autonumeric/dist/autoNumeric.min";
import $ from "jquery";
import {renderAmount} from "./utils";



export default class SessionFinanceAdminReport {
  constructor(app) {
    this.app = app;
    this.app.console.info("Session Finance Admin Report");
    
    $( document ).ready(function() {
      this.fundingAllocationAn = new AutoNumeric("#fundingAllocation");
      this._rerender();
    }.bind(this));
  }
    
  _recalculate() {
    var fundingPence=Math.floor(parseFloat(this.fundingAllocationAn.getNumericString())*100);
    var totalIncome=0;
    var totalCost=0;
    for (var sessionIx in this.app.finances) {
      if ($("#sessionCheck" + sessionIx).is(":checked")) {
        var session = this.app.finances[sessionIx];
        var income = parseInt(session["costAmount"]) + 
          parseInt(session["cancelledAmount"]) +
          parseInt(session["lateAmount"]) +
          parseInt(session["dnaAmount"]) +
          parseInt(session["driveAmount"]);
        var sessionCost = parseInt(session["sessionCostAmount"]);
        totalIncome += income;
        totalCost += sessionCost;
      }
    }
    $("#total_income").text(renderAmount(totalIncome));
    $("#total_cost"  ).text(renderAmount(0 - totalCost));
    var netTotal = totalIncome + totalCost;
    if (netTotal < 0)
      $("#total_net").addClass("loss");
    else
      $("#total_net").removeClass("loss");
    $("#total_net").text(renderAmount(netTotal));
    $("#total_susu_income").text(renderAmount(totalIncome + fundingPence));
    $("#total_susu_cost").text(renderAmount(0 - totalCost));
    var netSusu = totalIncome + totalCost + fundingPence;
    if (netSusu < 0)
      $("#total_susu_net").addClass("loss");
    else
      $("#total_susu_net").removeClass("loss");
    $("#total_susu_net").text(renderAmount(netSusu));
  }
  
  _rerender() {
    var financesHtml = "";
    for (var sessionIx in this.app.finances) {
      var session = this.app.finances[sessionIx];
      financesHtml += "<tr>";
      financesHtml += "  <td><input type=\"checkbox\" class=\"session-check\" id=\"sessionCheck" + sessionIx + "\" checked />" + session["label"] + " " + session["whenItIs"] + "</td>";
      var income = parseInt(session["costAmount"]) + 
        parseInt(session["cancelledAmount"]) +
        parseInt(session["lateAmount"]) +
        parseInt(session["dnaAmount"]) +
        parseInt(session["driveAmount"]);
      var sessionCost = parseInt(session["sessionCostAmount"]);
      financesHtml += "  <td>" + renderAmount(income) + "</td>";
      financesHtml += "  <td>" + renderAmount(0 - sessionCost) +"</td>";
      var netCost = renderAmount(income + sessionCost);
      var style = "";
      if (netCost < 0) {
        style = " class=\"loss\"";
      }
      financesHtml += "  <td" + style + ">" + netCost + "</td>";
    }
    $("#financialResultsBody").html(financesHtml);
    this._recalculate();
    $(".session-check").change(this._recalculate.bind(this));
  }
}