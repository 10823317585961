import $ from "jquery";
import _ from "underscore";
import {default as Promise} from "bluebird";

import EditScheduledSession from "./editScheduledSession";
import EditSession from "./editSession";
import EditSessionFrequency from "./editSessionFrequency";
import SpitFacebook from "./facebook";
import Home from "./home";
import Payments from "./payments";
import Register from "./register";
import RegistrationsAdmin from "./registrationsAdmin";
import ReviewDeposits from "./reviewDeposits";
import RunDirectDebits from "./runDirectDebits";
import SessionFinanceAdmin from "./sessionFinanceAdmin";
import SessionFinanceAdminReport from "./sessionFinanceAdminReport";
import SessionRegister from "./sessionRegister";
import UpdateInfo from "./updateInfo";
import UserAdmin from "./userAdmin";
import UserFinanceAdmin from "./userFinanceAdmin";
import ChangePassword from "./changePassword";
import "bootstrap";

export default class SpitfiresSessions {
  constructor(siteBase) {
    //The logger should only be disabled if we’re not in production.
    this.debugMode = (ENV !== "production");
    this.siteBase = siteBase;
    this.console = console;
  }
  
  alert(message, onClose) {
    var html = `<div class="modal" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Alert</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>` + message + `</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>`;
    var newNode = $(html);
    $("body").append(newNode);
    newNode.modal({"show": true});
    newNode.on("hidden.bs.modal", function() {
      newNode.remove();
      if (_.isFunction(onClose)) {
        onClose();
      }
    }.bind(this));
  }
  
  prompt(title, defaultVal, callback) {
    var html = `<div class="modal" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Input</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form>
          <div class="form-group">
            <label for="exampleInputEmail1">` + title + `</label>
            <input type="text" class="form-control" id="promptInput" value="` + defaultVal + `">
          </div>
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
          <button type="submit" class="btn btn-primary">Submit</button>
        </form>
      </div>
    </div>
  </div>
</div>`;
    var newNode = $(html);
    $("body").append(newNode);
    newNode.modal({"show": true});
    newNode.find("form").submit(function() {
      callback(newNode.find("input").val());
      newNode.modal("hide");
    }.bind(this));
    newNode.on("hidden.bs.modal", function() {
      newNode.remove();
    }.bind(this));
  }
  
  ask(title, message, buttons) {
    var html = `<div class="modal" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">` + title + `</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>` + message + `</p>
      </div>
      <div class="modal-footer">
        `;
    _.each(buttons, function(button) {
      html += "<button type=\"button\" class=\"btn " + button[1] + "\" data-dismiss=\"modal\">" + button[0] + "</button>";
    }, this);
    html += `
      </div>
    </div>
  </div>
</div>`;
    var newNode = $(html);
    $("body").append(newNode);
    _.each(buttons, function(buttonInfo) {
      var buttonNode = newNode.find("." + buttonInfo[1]);
      buttonNode.click(function() {
        newNode.modal("hide");
        if (buttonInfo[2] != null)
          buttonInfo[2]();
      });
    }, this);
    newNode.modal({"show": true});
    newNode.find("form").submit(function() {
      /* Not used */
    }.bind(this));
    newNode.on("hidden.bs.modal", function() {
      newNode.remove();
    }.bind(this));
  }
  
  changePassword() {
    this.changePassword = new ChangePassword(this);
  }
  
  editScheduledSession() {
    this.editScheduledSession = new EditScheduledSession(this);
  }
  
  editSession() {
    this.editSession = new EditSession(this);
  }
  
  editSessionFrequency() {
    this.editSessionFrequency = new EditSessionFrequency(this);
  }
  
  home() {
    this.home = new Home(this);
  }
  
  register() {
    this.register = new Register(this);
  }
  
  registrationsAdmin() {
    this.registrationsAdmin = new RegistrationsAdmin(this);
  }
  
  reviewDeposits() {
    this.reviewDeposits = new ReviewDeposits(this);
  }
  
  runDirectDebits() {
    this.runDirectDebits = new RunDirectDebits(this);
  }
  
  sessionFinanceAdmin() {
    this.sessionFinanceAdmin = new SessionFinanceAdmin(this);
  }
  
  sessionFinanceAdminReport() {
    this.sessionFinanceAdminReport = new SessionFinanceAdminReport(this);
  }
  
  sessionRegister() {
    this.sessionRegister = new SessionRegister(this);
  }
  
  updateInfo() {
    this.updateInfo = new UpdateInfo(this);
  }
  
  userAdmin() {
    this.userAdmin = new UserAdmin(this);
  }
  
  userFinanceAdmin() {
    this.userFinanceAdmin = new UserFinanceAdmin(this);
  }
  
  facebook(skipFabo) {
    this.facebook = new SpitFacebook(this, skipFabo);
  }
  
  makePayment() {
    this.getPayments().setupPhpForm();
  }
  
  getPayments() {
    if (_.isEmpty(this.payments))
      this.payments = new Payments(this);
    return this.payments; 
  }
  isDebug() {
    return this.debugMode;
  }
  
  post(backEndUrl, data, onSuccess, onDone, onFail) {
    if (!_.isFunction(onSuccess)) onSuccess = function() {};
    if (!_.isFunction(onDone))    onDone    = function() {};
    if (!_.isFunction(onFail))    onFail    = function() {};
    var app = this;
    var under = _;
    
    $.ajax({
      type: "POST",
      url: this.siteBase + "/src/php/json/" + backEndUrl,
      data: data,
      success: onSuccess,
      dataType: "json"
    }).done(onDone).fail(function(jqXHR, textStatus, errorThrown) {
      if (under.isString(jqXHR.responseText) && jqXHR.responseText.toString().indexOf("<br />") > -1) {
        app.alert(jqXHR.responseText);
      }
      
      onFail(jqXHR, textStatus, errorThrown);
    }.bind(this));
  }
  
  postPromise(backEndUrl, data) {
    return new Promise(function(resolve, reject) {
      this.post(backEndUrl, data, function(result) {
        resolve(result);
      }.bind(this), null, function(e) {
        reject(new Error(e));
      }.bind(this));
    }.bind(this));
  }
  
  goHome() {
    document.location.href = this.siteBase + "/src/php/home.php";
  }
  
  reloadPage() {
    if (this.doIHaveGetParameters()) {
      window.location = window.location.href.split("?")[0];
    } else {
      window.location.reload();
    }
  }
  
  doIHaveGetParameters() {
    var field = "q";
    var url = window.location.href;
    if(url.indexOf("?" + field + "=") != -1)
      return true;
    else if(url.indexOf("&" + field + "=") != -1)
      return true;
    return false;
  }
}
