import $ from "jquery";
import Cookies from "js-cookie";
import * as Facebook from "fb-sdk-wrapper";

export default class SpitFacebook {
  constructor(app, skipFabo) {
    this.app = app;
    this.faboLoading=true;
    this.isDisconnected=false;
    this.skipFabo=skipFabo;
    this.faboAppId="277618166177365";
    if (window.location.host == "localhost") {
      this.faboAppId="1355741837854173";
    } else if (window.location.host == "test.myspitfires.club") {
      this.faboAppId="486958318437782";
    }
    this.app.console.info("Facebook");
  
    $( document ).ready(function() {
      $("#sign-in-form").submit(this.signIn.bind(this));
      if (skipFabo) {
        $("#status").remove();
        $("#Login").show();
      }
      if (this.isDisconnected==true) {
        $("#errors *").remove();
        $("#errors").append("<div class=\"alert alert-danger alert-dismissible\" role=\"alert\"><button type=\"button\" class=\"close\" data-dismiss=\"alert\"><span aria-hidden=\"true\">&times;</span><span class=\"sr-only\">Close</span></button>Application operating offline as internet connection lost.</div>");
        $("#status").remove();
        $("#Login").show();
      }
    }.bind(this));

    this.loadFacebook();
  }

  showLogin() {
    $( document ).ready(function() {
      $("#status").remove();
      $("#Login").show();
    }.bind(this));
  }
  
  // This is called with the results from from FB.getLoginStatus().
  statusChangeCallback(response) {
    this.faboLoading=false;
    this.app.console.debug("statusChangeCallback");
    this.app.console.debug(response);
    // The response object is returned with a status field that lets the
    // app know the current login status of the person.
    // Full docs on the response object can be found in the documentation
    // for FB.getLoginStatus().
    if (response.status === "connected") {
      // Logged into your app and Facebook.
      this.signIn(null, response);
    } else {
      //if (response.status === "not_authorized") {
      // The person is logged into Facebook, but not your app.
      this.showLogin();
    }
  }

  // This is called when someone finishes with the Login
  // Button.  See the onlogin handler attached to it in the sample
  // code below.
  checkLoginState() {
    Facebook.getLoginStatus.then(this.statusChangeCallback.bind(this)).catch(this.facebookFailed.bind(this));
  }

  facebookLoaded() {
    Facebook.init({
      appId:  this.faboAppId,
      cookie: true,
      version: "v19.0"
    }).then(this.checkLoginState.bind(this)).catch(this.facebookFailed.bind(this));
  }
  
  facebookFailed() {
    this.app.console.warn("Failed fabo login");
    this.showLogin();
  }
  
  // Load the SDK asynchronously
  loadFacebook() {
    if (!this.skipFabo) {
      Facebook.load().then(this.facebookLoaded.bind(this)).catch(this.facebookFailed.bind(this));
    } else {
      this.showLogin();
    }
  }
    
  fbLogin(){
    Facebook.login({scope: "email"}).then(this.statusChangeCallback.bind(this)).catch(this.facebookFailed.bind(this));
  }

  signedIn(responseData) {
    $("#userEmail").removeAttr("disabled");
    $("#userPassword").removeAttr("disabled");
    
    if (responseData.success!=true) {
      $("#errors").append("<div class=\"alert alert-danger alert-dismissible\" role=\"alert\"><button type=\"button\" class=\"close\" data-dismiss=\"alert\"><span aria-hidden=\"true\">&times;</span><span class=\"sr-only\">Close</span></button>" + responseData.message  + ".</div>");
    } else {
      Cookies.set("SpitSessionId", responseData.sessionId, { expires: 7, path: this.app.siteBase + "/" });
      this.app.goHome();
    }
  }

  signedInFail() {
    $("#login-fail").show();
    $("#userEmail").removeAttr("disabled");
    $("#userPassword").removeAttr("disabled");
    $("#errors").append("<div class=\"alert alert-danger alert-dismissible\" role=\"alert\"><button type=\"button\" class=\"close\" data-dismiss=\"alert\"><span aria-hidden=\"true\">&times;</span><span class=\"sr-only\">Close</span></button>Sorry your login was rejected, please try again.</div>");
  }

  signIn(event) {
    if (event != null) 
      event.preventDefault();

    $("#errors *").remove();

    $("#userEmail").attr("disabled", "disabled");
    $("#userPassword").attr("disabled", "disabled");
    this.app.post("loginJSON.php", { "email": $("#userEmail").val(), "password": $("#userPassword").val() }, this.signedIn.bind(this), null, this.signedInFail.bind(this));
  }

  _disconnected() {
    this.isDisconnected=true;
    if (this.faboLoading==true) {
      this.faboLoading=false;
      $("#errors *").remove();
      $("#errors").append("<div class=\"alert alert-danger alert-dismissible\" role=\"alert\"><button type=\"button\" class=\"close\" data-dismiss=\"alert\"><span aria-hidden=\"true\">&times;</span><span class=\"sr-only\">Close</span></button>Application operating offline as internet connection lost.</div>");
      $("#status").remove();
      $("#Login").show();
    }
  }
  //window.onDisconnected=_disconnected();
}