import $ from "jquery";
import {verifyPresent, verifyEmail} from "./utils";




export default class UpdateInfo {
  constructor(app) {
    this.app = app;
    this.app.console.info("Update Info");

    $( document ).ready(function() {
      $("#update-info-form").submit(this.updateInfo.bind(this));
    }.bind(this));
  }
  
  updateInfoSuccess(responseData) {
    $("#update-info-form input").removeAttr("disabled");
    
    if (responseData.success!=true) {
      $("#errors").append("<div class=\"alert alert-danger alert-dismissible\" role=\"alert\"><button type=\"button\" class=\"close\" data-dismiss=\"alert\"><span aria-hidden=\"true\">&times;</span><span class=\"sr-only\">Close</span></button>" + responseData.message  + ".</div>");
    } else {
      this.app.goHome();
    }
  }
  
  updateInfoFail() {
    $("#login-fail").show();
    $("#update-info-form input").removeAttr("disabled");
  
    $("#errors").append("<div class=\"alert alert-danger alert-dismissible\" role=\"alert\"><button type=\"button\" class=\"close\" data-dismiss=\"alert\"><span aria-hidden=\"true\">&times;</span><span class=\"sr-only\">Close</span></button>Sorry your update was rejected, please try again later.</div>");
  }
  
  sendUpdateInfoOrUpdate() {
    $("#update-info-form input").attr("disabled", "disabled");
    var data=null;
    data={ 
      
    };
    $("#update-info-form input").each(function() {
      var node=$(this);
      var nodename=node.attr("id");
      if (nodename.length > 2 && nodename.substring(nodename.length-2) == "In") {
        nodename=nodename.substring(0, nodename.length-2); //Take off In
        data[nodename]=node.val();
      }
    });
    
    this.app.post("updateInfoJSON.php", data, this.updateInfoSuccess.bind(this), null, this.updateInfoFail.bind(this));
  }
  
  updateInfo(event) {
    event.preventDefault();
    
    $("#errors *").remove();
    
    var fail=!verifyEmail();
    fail|=!verifyPresent("#forenameIn", "#forenameDiv");
    fail|=!verifyPresent("#surnameIn", "#surnameDiv");
    
    if (fail==false) {
      this.sendUpdateInfoOrUpdate(false);
    }
  }
}