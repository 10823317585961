import $ from "jquery";
import _ from "underscore";
import {escapeHtml,showResult} from "./utils";

export default class Home {
  constructor(app) {
    this.app = app;
    this.pendingRegistrationId=0;
    this.registration=[];
    this.app.console.info("startup home");
    
    $( document ).ready(function() {
      $(".btn.register").click(this.register.bind(this));
      $("#saveRegistration").click(this.sendRegister.bind(this));
      $("#saveAttendSession").click(this.sendMakeBooking.bind(this));
      $("#cancelSessionSend").click(this.sendCancelBooking.bind(this));
      
      for (var ix in this.app.bookings) {
        this.addBooking(this.app.bookings[ix]);
      }
    }.bind(this));
  }
  
  setEnabled(isEnabled) {
    if (isEnabled) {
      $("#saveRegistration").show();
      $("#cancelRegistration").show();
    } else {
      $("#registrationDialog .errors *").remove();
      $("#saveRegistration").hide();
      $("#cancelRegistration").hide();
    }
  }
  
  registrationSuccess(responseData) {
    this.setEnabled(true);
    
    if (responseData.success!=true) {
      $("#registrationDialog .errors").append("<div class=\"alert alert-danger alert-dismissible\" role=\"alert\"><button type=\"button\" class=\"close\" data-dismiss=\"alert\"><span aria-hidden=\"true\">&times;</span><span class=\"sr-only\">Close</span></button>" + responseData.message  + ".</div>");
    } else {
      this.app.reloadPage();
    }
  }
  
  registrationFail() {
    this.setEnabled(true);
    $("#registrationDialog .errors").append("<div class=\"alert alert-danger alert-dismissible\" role=\"alert\"><button type=\"button\" class=\"close\" data-dismiss=\"alert\"><span aria-hidden=\"true\">&times;</span><span class=\"sr-only\">Close</span></button>Sorry there was an unexpected error, please try again later.</div>");
  }
  
  sendRegister(event) {
    event.preventDefault();
  
    var fail=false;
    for (var ix in this.registration[9]) {
      var info=this.registration[9][ix];
      var good=new RegExp(info.regex).test($("#optField"+ix).val());
      fail|=!good;
      showResult(good, $("#optField"+ix).parent());
    }
  
    if (fail==false) {
      this.doSendReg();
    }
  }
  
  doSendReg() {
    this.setEnabled(false);
    var optFields={};
    for (var ix in this.registration[9]) {
      var info=this.registration[9][ix];
      optFields[info.infoKey]=$("#optField"+ix).val();
    }
    var data={ 
      "regId": this.pendingRegistrationId,
      "optFields": optFields,
      "cost": $("#costs").val()
    };
  
    this.app.post("createUserRegistrationJSON.php", data, this.registrationSuccess.bind(this), null, this.registrationFail.bind(this));
  }
  
  addBookingSuccess(data) {
    if (data.success) {    
      $("#booking-row-" + this.booking[0]).removeClass("not-booked");
      $("#booking-row-" + this.booking[0]).addClass("booked");
      $("#cancel-" + this.booking[0]).show();
      $("#currentBalance").html(data.rbalance);
    } else {
      this.app.alert("Sorry failed to make that booking: " + data.message);
      $("#make-" + this.booking[0]).show();
    }
  }
  
  addBookingFail() {
    this.app.alert("Sorry failed to make that booking, please try again later.");
    $("#make-" + this.booking[0]).show();
  }
  
  cancelBookingSuccess(data) {
    if (data.success) {    
      $("#booking-row-" + this.booking[0]).removeClass("booked");
      $("#booking-row-" + this.booking[0]).addClass("not-booked");
      $("#make-" + this.booking[0]).show();
      $("#currentBalance").html(data.rbalance);
    } else {
      this.app.alert("Sorry failed to cancel that booking: " + data.message);
      $("#cancel-" + this.booking[0]).show();
    }
  }
  
  cancelBookingFail() {
    this.app.alert("Sorry failed to cancel that booking, please try again later.");
    $("#cancel-" + this.booking[0]).show();
  }
  
  makeBooking(event) {
    event.preventDefault();
    this.booking=event.data;
    
    this.app.getPayments().ensureFunds(this.booking[19], "Session booking: " + this.booking[3]).then(this.makeBookingAfterCheck.bind(this, event)).catch(function(e) {
      this.app.console.warn(e);
      var message = "Unable to ensure you have sufficient funds; please try again later.";
      if (e instanceof Error) {
        message = message + " " + e.message;
      }
      this.app.alert(message);
    }.bind(this));
  }
  
  makeBookingAfterCheck() {
    this.app.goCardlessId == null && 
    $("#session-label").text(this.booking[3]);
    $("#session-date-start").text(this.renderBookingTime(this.booking));
    $("#session-costs").html(this.booking[19]);
    $("#session-fine-dna").html(this.booking[20]);
    $("#session-fine-last-second").html(this.booking[21]);
    $("#session-rebate-drive").html(this.booking[18]);
    $("#session-additional-info").text(this.booking[17]);
        
    $("#sessionDialog").modal("show");
  }
  
  requestBooking(event) {
    event.preventDefault();
    this.booking=event.data;
    
    this.app.getPayments().ensureFunds(this.booking[19], "Session booking: " + this.booking[3]).then(this.requestBookingAfterCheck.bind(this, event));
  }
  
  requestBookingAfterCheck() {
    $("#session-request-label").text(this.booking[3]);
    $("#session-request-date-start").text(this.renderBookingTime(this.booking));
    $("#session-request-costs").html(this.booking[19]);
    $("#session-request-fine-dna").html(this.booking[20]);
    $("#session-request-fine-last-second").html(this.booking[21]);
    $("#session-request-rebate-drive").html(this.booking[18]);
    $("#session-request-additional-info").text(this.booking[17]);
  
    $("#mailSimon").attr("href","mailto:smg496@hotmail.com?subject=Late Registration For " + this.booking[3] + " on " + this.renderBookingTime(this.booking) + "&body=Hi Simon, can you register me for " + this.booking[3] + " on " + this.renderBookingTime(this.booking) + " please, thanks " + this.app.forename + " " + this.app.surname);
    $("#mailBrad").attr("href","mailto:bradley.j.wallis@googlemail.com?subject=Late Registration For " + this.booking[3] + " on " + this.renderBookingTime(this.booking) + "&body=Hi Brad, can you register me for " + this.booking[3] + " on " + this.renderBookingTime(this.booking) + " please, thanks " + this.app.forename + " " + this.app.surname);
  
    $("#sessionRequestDialog").modal("show");
  }
  
  infoBooking(event) {
    event.preventDefault();
    this.booking=event.data;
  
    $("#sessionInfoDialogWhen").text(this.booking[1]);
    $("#sessionInfoDialogBookingCloses").text(this.booking[24]);
    $("#sessionInfoDialogInfo").text(this.booking[17].replace("\\\\\\\"", "\""));
    $("#sessionInfoDialog").modal("show");
  }
  
  _why(event) {
    event.preventDefault();
    this.booking=event.data;
    
    this.app.alert(this.booking[6]);
  }
  
  sendMakeBooking(event) {
    event.preventDefault();
    
    $("#sessionDialog").modal("hide");
      
    this.app.post("bookingAddJSON.php", { "scheduledSessionId": this.booking[0] }, this.addBookingSuccess.bind(this), null, this.addBookingFail.bind(this));
    
    $("#make-" + this.booking[0]).hide();
  }
  
  cancelBooking(event) {
    event.preventDefault();
    this.booking=event.data;  
    
    $("#cancelSessionLabel").text(this.booking[3]);
    $("#cancelSessionDate").text(this.renderBookingTime(this.booking));
  
    $("#sessionCancelDialog").modal("show");
  }
  
  
  sendCancelBooking(event) {
    event.preventDefault();
    
    $("#sessionCancelDialog").modal("hide");
  
    this.app.post("bookingCancelJSON.php", { "scheduledSessionId": this.booking[0] }, this.cancelBookingSuccess.bind(this), null, this.cancelBookingFail.bind(this));
    
    $("#cancel-" + this.booking[0]).hide();
  }
  
  renderBookingTime(booking) {
    var yearMonth=booking[1].split("-");
    var dayTime=yearMonth[2].split(" ");
    var time=dayTime[1].split(":");
    return new Date(yearMonth[0], yearMonth[1]-1, dayTime[0], time[0], time[1], time[2]).format("ddd dd-mmm h:MMtt");
  }
  
  addBooking(booking) {
    var categoryId=booking[22];
    var bodyNodeName="#bookings-table-"+categoryId;
    if ($(bodyNodeName).length == 0){
      $("#bookings-div").append("<h3>"+booking[23]+"</h3><table class=\"table table-hover\" id=\"bookings-table-"+categoryId+"\"><thead><tr><th>What</th><th>When</th><th>&nbsp;</th></tr></thead><tbody></tbody></table>");
    }
    var bodyNode=$(bodyNodeName + " tbody");
    if (booking[4]!=null) {
      bodyNode.append("<tr class=\"cancelled-session\"><td>" + booking[3] + "</td><td>" + booking[1] + "</td><td><button type=\"button\" class=\"btn btn-secondary why\" id=\"why-" + booking[0] + "\">Why</button></td></tr>");
      $("#why-" + booking[0]).click(booking, this._why.bind(this));
    } else {
      var clazz="";
      if (booking[7]!=null) {
        clazz="booked";
      } else {
        clazz="not-booked";
      }
      //var yearMonth=booking[1].split("-");
      //var dayTime=yearMonth[2].split(" ");
      //var time=dayTime[1].split(":");
      if (booking[25]==0) 
        bodyNode.append("<tr class=\"" + clazz + "\" id=\"booking-row-" + booking[0] + "\"><td>" + booking[3] + "  <span id=\"info-"+booking[0]+"\" class=\"fas fa-info-circle\"></span></td><td>" + this.renderBookingTime(booking) + "</td><td><button type=\"button\" class=\"btn btn-secondary\" id=\"request-" + booking[0] + "\">Request Booking</button></td></tr>");
      else
        bodyNode.append("<tr class=\"" + clazz + "\" id=\"booking-row-" + booking[0] + "\"><td>" + booking[3] + "  <span id=\"info-"+booking[0]+"\" class=\"fas fa-info-circle\"></span></td><td>" + this.renderBookingTime(booking) + "</td><td><button type=\"button\" class=\"btn btn-secondary cancel\" id=\"cancel-" + booking[0] + "\">Cancel Booking</button><button type=\"button\" class=\"btn btn-secondary\" id=\"make-" + booking[0] + "\">Make Booking</button></td></tr>");
      
      if (booking[25]==0) {
        $("#request-" + booking[0]).click(booking, this.requestBooking.bind(this));
      } else {
        $("#cancel-" + booking[0]).click(booking, this.cancelBooking.bind(this));
        $("#make-" + booking[0]).click(booking, this.makeBooking.bind(this));
      }
      $("#info-" + booking[0]).click(booking, this.infoBooking.bind(this));
      if (booking[7]!=null) {
        $("#make-" + booking[0]).hide();
        $("#request-" + booking[0]).hide();
      } else {
        $("#cancel-" + booking[0]).hide();
      }
    }
  }
  
  register(event) {
    event.preventDefault();
    var id=$(event.target).attr("id").substr(9);
    this.pendingRegistrationId=id;
    for (var ix in this.app.registrations) {
      var registrationTmp = this.app.registrations[ix];
      if (registrationTmp[0]==id) {
        this.registration=registrationTmp;
        break;
      }
    }
    var minCost = null;
    var maxCost = null;
    _.each(this.registration[10], function(info) {
      if (maxCost == null) {
        minCost = info.cost;
        maxCost = info.cost;
      } else {
        if (maxCost < info.cost) {
          maxCost = info.cost;
        }
        if (minCost > info.cost) {
          minCost = info.cost;
        }
      }
    }, this);

    this.app.getPayments().ensureFunds(maxCost, "Registration " + this.registration[1]).
      then(this.registerAfterCheck.bind(this, id)).
      catch(function(e) {
        this.app.console.warn(e);
        var message = "Unable to ensure you have sufficient funds; please try again later.";
        if (e instanceof Error) {
          message = message + " " + e.message;
        }
        this.app.alert(message);
      }.bind(this));
  } 
  
  registerAfterCheck(id) {
    var ix = null;
    var info = null;
    
    $("#register-label").text($("#register-label-" + id).val());
    $("#register-date").text($("#register-expires-" + id).val());
    $("#register-cost").text($("#register-cost-" + id).val());
    $(".reg-data *").remove();
    $(".errors *").remove();
    $("#costs option").remove();
    var regData=$(".reg-data");
    for (ix in this.registration[9]) {
      info=this.registration[9][ix];
      regData.append(" <div class=\"form-group\">"+
              "    <label for=\"optField"+ix+"\">"+escapeHtml(info.label)+"</label>"+
              "    <input type=\"text\" class=\"form-control\" id=\"optField"+ix+"\">"+
              "</div>");
      for (var uiIx in this.app.userInfoMap) {
        if (uiIx==info.infoKey) {
          $("#optField"+ix).val(this.app.userInfoMap[uiIx]);
          break;  
        }
      }
    }
    for (ix in this.registration[10]) {
      info=this.registration[10][ix];
      $("#costs").append("<option value=\""+info["regCostId"]+"\">"+info["rcost"]+" - "+escapeHtml(info["label"])+"</option>");
      $("#costs").val(info["regCostId"]);
      
    }
    $("#registrationDialog").modal("show");
  }
}