import $ from "jquery";




export default class RunDirectDebits {
  constructor(app) {
    this.paymentIx=-1;
    this.paymentUsers=[];
    this.stopped=false;
    this.app = app;
    this.app.console.info("Run Direct Debits");
    
    $( document ).ready(function() {
      $("#start-dd").click(this.startDD.bind(this));
      $("#stop-dd").click(this.stopDD.bind(this));
      $("#stop-dd").hide();
      $("#progress-dd").hide();
    }.bind(this));
  }
  
  saveSuccess(result) {
    if (result.success) {
      $("#user-status-" + this.paymentUsers[this.paymentIx]).text("Success: " + result.message);
    } else {
      $("#user-status-" + this.paymentUsers[this.paymentIx]).text("Failed: " + result.message);
    }
    this.doNextDD();
  }
  
  saveFail() {
    $("#user-status-" + this.paymentUsers[this.paymentIx]).text("Failed to communicate with server.");
    this.doNextDD();
  }
  
  sendPayment(userId) {
    this.app.post("gocardlessMakeBillJSON.php", { "userId": userId, "reason": "post-pay billing cycle" }, this.saveSuccess.bind(this), null, this.saveFail.bind(this));
  }
    
  stopDD(event) {
    event.preventDefault();
    $("#stop-dd").hide();
    this.stopped=true;
  }
  
  doNextDD() {
    ++this.paymentIx;
    var progressPercent = 100;
    if (this.paymentUsers.length > 0) {
      progressPercent = parseInt(parseFloat(this.paymentIx) / parseFloat(this.paymentUsers.length) * 100);
    }
    $(".progress-bar").css("width", progressPercent+"%").attr("aria-valuenow", progressPercent); 
    $(".progress-bar").text(progressPercent  + "% Complete");
    if (this.stopped) {
      return;
    }
    if (this.paymentIx>=this.paymentUsers.length) {
      $("#stop-dd").hide();
      $("#errors").append("<div class=\"alert alert-success alert-dismissible\" role=\"alert\"><button type=\"button\" class=\"close\" data-dismiss=\"alert\"><span aria-hidden=\"true\">&times;</span><span class=\"sr-only\">Close</span></button>Finished sending direct debits.</div>");
      return;
    }
    this.sendPayment(this.paymentUsers[this.paymentIx]);
  }
  
  startDD(event) {
    event.preventDefault();
    $("#start-dd").hide();
    $("#stop-dd").show();
    $("#errors *").remove();
    $("#progress-dd").show();
    $(".include-user:checked").each(function(ix, element) {
      this.paymentUsers.push(parseInt($(element).attr("id").substr(5)));
    }.bind(this));
    this.doNextDD();
  }
}
