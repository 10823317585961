import {renderAmount} from "./utils";
import $ from "jquery";

export default class MakePaymentGoCardless {
  constructor(app) {
    this.paymentToMakeAmount = 0;
    this.reason = null;
    this.app = app;
  }
  
  makePaymentAskForConfirmation(amount, reason) {
    var html = `
      <div class="modal fade" id="makePaymentDialog" tabindex="-1" role="dialog" aria-labelledby="makePaymentDialogLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Close</span></button>
              <h4 class="modal-title" id="makePaymentDialogLabel">Confirm You Want To Make A Payment</h4>
            </div>
            <div class="modal-body">
          Are you sure you want to pay ` + renderAmount(amount) + `, this will be taken from your bank account in around the next three working days, after confirming below GoCardless will e-mail you to confirm this? Should you suddenly realise you need to cancel this you can do so from the <a target="_blank" href="https://customer.gocardless.com/payments">GoCardless</a> website, this will automatically correct your spitfires balance no need to notify Simon or the committee.
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" id="cancelMakePayment">Cancel</button>
              <button type="button" class="btn btn-primary" id="confirmMakePayment">Make Payment</button>
            </div>
          </div>
        </div>
      </div>
      `;
    
    this.dialog = $(html);
    $("body").append(this.dialog);
    this.dialog.modal("show");
    this.paymentToMakeAmount = amount;
    this.reason = reason;
    this.didConfirm = false;
    return new Promise(function(resolve, reject) {
      this.resolve = resolve;
      this.reject  = reject;
      this.dialog.find("#cancelMakePayment").click( this.makePaymentCancel .bind(this));
      this.dialog.find("#confirmMakePayment").click(this.makePaymentConfirm.bind(this));
      this.dialog.on("hidden.bs.modal", function() {
        if (!this.didConfirm) {
          this.dialog.remove();
          this.dialog = null;
          this.callReject();
        }
      }.bind(this));
    }.bind(this));
  }
  
  setupGoCardless() {
    return this.app.postPromise("goCardlessJSON.php").then(function(data) {
      if (data.success) {    
        window.location.replace(data.url);        
      } else {
        throw Error("Sorry failed to create direct debit session: " + data.message);
      }
    }.bind(this));
  }

  makePaymentConfirm(event) {
    this.didConfirm = true;
    event.preventDefault();
    this.dialog.modal("hide");
    return this.app.postPromise("gocardlessMakeBillUserJSON.php", { 
      amount: this.paymentToMakeAmount,
      reason: this.reason
    }).then(
      function(result) {
        if (!result.success) {
          if ("requireSetup" in result && result["requireSetup"]) {
            this.setupGoCardless().then(this.makePaymentConfirm.bind(this, event)).catch(this.callReject.bind(this));
            return;
          }
          this.callReject(new Error(result.message));
          return;
        }
        this.callResolve();
      }.bind(this))
      .catch(function() {
        this.callReject();
      }.bind(this));
  }
  
  callReject(e) {
    var reject   = this.reject;
    this.reject  = null;
    this.resolve = null;
    if (reject  != null) {
      if (e instanceof Error)
        reject(e);
      else
        reject(new Error());
    }
      
  }
  
  callResolve() {
    var resolve  = this.resolve;
    this.reject  = null;
    this.resolve = null;
    if (resolve  != null)
      resolve();
  }
  
  makePaymentCancel(event) {
    event.preventDefault();
    this.dialog.modal("hide");
    this.callReject();
  }

}

