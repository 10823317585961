import {renderAmount} from "./utils";
import $ from "jquery";

export default class MakePaymentBankTransfer {
  constructor(app) {
    this.paymentToMakeAmount = 0;
    this.app = app;
  }
  
  madePaymentAskForTransfer(reference) {
    var html = `<div class="modal fade" id="madePaymentAskTransfer" tabindex="-1" role="dialog" aria-labelledby="madePaymentAskTransfeLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Close</span></button>
        <h4 class="modal-title" id="madePaymentAskTransfeLabel">Make Payment Transfer</h4>
      </div>
      <div class="modal-body">
        <div class="container"><h2>The next step is to transfer the payment to our bank account using your online banking</h2>
    <p>You must now login to your online banking to complete this payment. When completing the payment via your online banking you must use the same sort code, account number, reference and amount as shown below. You'll also find links to common UK banks below.</p>
    <table class="table">
      <tr><th>Account Name</th><td>SUIIHC</td></tr>
      <tr><th>Sort Code</th><td>60-20-44</td></tr>
      <tr><th>Account Number</th><td>99659492</td></tr>
      <tr><th>Reference</th><td id="txn-reference">` + reference + `</td></tr>
      <tr><th>Amount</th><td id="txn-amount">` + renderAmount(this.paymentToMakeAmount) + `</td></tr>
    </table>
    <hr>
    <div class="row">
      <div class="col-sm-2"><p class="text-center"><a href="https://bank.barclays.co.uk/olb/auth/LoginLink.action"><img src="<?php echo SITE_URL; ?>/src/img/banks/barclays.gif" class="bank" /></a></p></div>
      <div class="col-sm-2"><p class="text-center"><a href="https://onlinebanking.nationwide.co.uk/AccessManagement/Login"><img src="<?php echo SITE_URL; ?>/src/img/banks/nationwide-logo.png" class="bank" /></a></p></div>
      <div class="col-sm-2"><p class="text-center"><a href="https://www.hsbc.co.uk/1/2/!ut/p/c5/04_SB8K8xLLM9MSSzPy8xBz9CP0os3gDgzAfSycDUy8LAzNDbz8vbzMDKADKR5rFO7s7epiY-wD5YZ6uBp4mTiYGpr5uhgaexsToRpd3Q8jj1-3nkZ-bql-QGxpR7qioCACU4XGn/dl3/d3/L2dBISEvZ0FBIS9nQSEh/?idv_cmd=idv.Logoff&nextPage=hsbc.MyHSBC_pib&IDV_URL=hsbc.MyHSBC_pib"><img src="<?php echo SITE_URL; ?>/src/img/banks/hsbc-logo.gif" class="bank" /></a></p></div>
      <div class="col-sm-2"><p class="text-center"><a href="https://online.lloydsbank.co.uk/personal/logon/login.jsp?WT.ac=PLO0512"><img src="<?php echo SITE_URL; ?>/src/img/banks/lloyds_bank_logo.gif" class="bank" /></a></p></div>
      <div class="col-sm-2"><p class="text-center"><a href="https://online.tsb.co.uk/personal/logon/login.jsp?WT.ac=hpIBlogon"><img src="<?php echo SITE_URL; ?>/src/img/banks/tsb.png" class="bank" /></a></p></div>
      <div class="col-sm-2"><p class="text-center"><a href="https://retail.santander.co.uk/LOGSUK_NS_ENS/BtoChannelDriver.ssobto?dse_operationName=LOGON&dse_processorState=initial&redirect=S"><img src="<?php echo SITE_URL; ?>/src/img/banks/santander.gif" class="bank" /></a></p></div>
    </div>
    <div class="row">
      <div class="col-sm-2"><p class="text-center"><a href="https://www.nwolb.com/default.aspx?refererident=A573CAC19FBF7E3994387576A2F58CF44DDC3D13&cookieid=21260&noscr=false&CookieCheck=2014-10-05T13:38:36"><img src="<?php echo SITE_URL; ?>/src/img/banks/natwest.gif" class="bank" /></a></p></div>
      <div class="col-sm-2"><p class="text-center"><a href="https://www.tescobank.com/sss/auth"><img src="<?php echo SITE_URL; ?>/src/img/banks/tesco.png" class="bank" /></a></p></div>
      <div class="col-sm-2"><p class="text-center"><a href="https://www.rbsdigital.com/default.aspx?refererident=ADE44A655818DD843D3E84019962152F27081F62&cookieid=33936&noscr=false&CookieCheck=2014-10-05T13:40:00"><img src="<?php echo SITE_URL; ?>/src/img/banks/rbs.gif" class="bank" /></a></p></div>
      <div class="col-sm-2"><p class="text-center"><a href="https://www.halifax-online.co.uk/personal/logon/login.jsp"><img src="<?php echo SITE_URL; ?>/src/img/banks/halifax.gif" class="bank" /></a></p></div>
      <div class="col-sm-2"><p class="text-center"><a href="https://personal.co-operativebank.co.uk/CBIBSWeb/start.do"><img src="<?php echo SITE_URL; ?>/src/img/banks/coop.gif" class="bank" /></a></p></div>
      <div class="col-sm-2"><p class="text-center"><a href="https://www7.marksandspencer.com/1/2/!ut/p/c5/04_SB8K8xLLM9MSSzPy8xBz9CP0os3gDgzAfSycDUy8LAzNDbz8vbzMDKNAvyHZUBAAEuMrS/"><img src="<?php echo SITE_URL; ?>/src/img/banks/m_n_s.png" class="bank" /></a></p></div>
    </div></div></div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal">Done</button>
      </div>
    </div>
  </div>
</div>`;
    
    this.dialog = $(html);
    $("body").append(this.dialog);
    this.dialog.modal("show");
    this.dialog.on("hidden.bs.modal", function() {
      this.dialog.remove();
      this.dialog = null;
      this.callResolve();
    }.bind(this));
  }
  
  makePaymentAskForConfirmation(amount, reason) {
    var html = `
<div class="modal fade" id="confirmPaymentDialog" tabindex="-1" role="dialog" aria-labelledby="confirmPaymentDialogLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Close</span></button>
        <h4 class="modal-title" id="confirmPaymentDialogLabel">Confirm Payment</h4>
      </div>
      <div class="modal-body">
    <p>Please confirm you are going to make a payment for ` + renderAmount(amount) + `. After pressing OK please then go to your bank and transfer the money using an online bank transfer; bank account details will be shown upon confirmation.</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-danger" id="confirmPaymentSend">OK</button>
      </div>
    </div>
  </div>
</div>`;
      
    this.dialog = $(html);
    $("body").append(this.dialog);
    this.dialog.modal("show");
    this.paymentToMakeAmount = amount;
    this.reason = reason;
    return new Promise(function(resolve, reject) {
      this.resolve = resolve;
      this.reject  = reject;
      this.dialog.find("#cancelMakePayment").click( this.makePaymentCancel .bind(this));
      this.dialog.find("#confirmMakePayment").click(this.makePaymentConfirm.bind(this));
      this.dialog.on("hidden.bs.modal", function() {
        this.dialog.remove();
        this.dialog = null;
        this.callReject();
      }.bind(this));
    }.bind(this));
  }

  makePaymentConfirm(event) {
    event.preventDefault();
    this.dialog.modal("hide");
    return this.app.postPromise("makePaymentJSON.php", { 
      amount: this.paymentToMakeAmount,
      reason: this.reason
    }).then(
      function(result) {
        if (!result.success) {
          this.callReject(new Error(result.message));
          return;
        }
        this.madePaymentAskForTransfer(result.txnRef);
      }.bind(this))
      .catch(function() {
        this.callReject();
      }.bind(this));
  }
  
  callReject() {
    var reject   = this.reject;
    this.reject  = null;
    this.resolve = null;
    if (reject  != null)
      reject(new Error());
  }
  
  callResolve() {
    var resolve  = this.resolve;
    this.reject  = null;
    this.resolve = null;
    if (resolve  != null)
      resolve();
  }
  
  makePaymentCancel(event) {
    event.preventDefault();
    this.dialog.modal("hide");
    this.callReject();
  }
}

