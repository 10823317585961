import {verifyPresent, verifyConfirmPassword, verifyEmail} from "./utils";
import $ from "jquery";
import _ from "underscore";




export default class Register {
  constructor(app) {
    this.isUpdateG = false;
    this.app = app;
    this.app.console.info("Register");
    
    $( document ).ready(function() {
      $("#register-form").submit(this.register.bind(this));
    }.bind(this));
  }
  
  disableEnable(disableNow) {
    var elems = ["#emailIn","#passIn","#passConfirmIn","#forenameIn","#surnameIn"];
    _.each(elems, function(elem) {
      if (disableNow) {
        $(elem).attr("disabled", "disabled");
      } else {
        $(elem).removeAttr("disabled");
      }
    }, this);
  }
  
  registerSuccess(responseData) {
    this.disableEnable(false);
    
    if (responseData.success!=true) {
      $("#errors").append("<div class=\"alert alert-danger alert-dismissible\" role=\"alert\"><button type=\"button\" class=\"close\" data-dismiss=\"alert\"><span aria-hidden=\"true\">&times;</span><span class=\"sr-only\">Close</span></button>" + responseData.message  + ".</div>");
    } else {
      this.app.goHome();
    }
  }
  
  registerFail() {
    $("#login-fail").show();
    this.disableEnable(false);
    if (this.isUpdateG) {
      $("#errors").append("<div class=\"alert alert-danger alert-dismissible\" role=\"alert\"><button type=\"button\" class=\"close\" data-dismiss=\"alert\"><span aria-hidden=\"true\">&times;</span><span class=\"sr-only\">Close</span></button>Sorry your update was rejected, please try again later.</div>");
    } else {
      $("#errors").append("<div class=\"alert alert-danger alert-dismissible\" role=\"alert\"><button type=\"button\" class=\"close\" data-dismiss=\"alert\"><span aria-hidden=\"true\">&times;</span><span class=\"sr-only\">Close</span></button>Sorry your registration was rejected, please try again later.</div>");
    }
  }
  
  sendRegisterOrUpdate(isUpdate) {
    this.isUpdateG=isUpdate;
    this.disableEnable(true);
    var url=null;
    var data=null;
    
    if (isUpdate) {
      data={ "email": $("#emailIn").val(), "forename": $("#forenameIn").val(), "surname": $("#surnameIn").val() };
      url="updateUserDetailsJSON.php";
    } else {
      data={ "email": $("#emailIn").val(), "password": $("#passIn").val(), "forename": $("#forenameIn").val(), "surname": $("#surnameIn").val() };
      url="registerJSON.php";
    }
    this.app.post(url, data, this.registerSuccess.bind(this), null, this.registerFail.bind(this));
  }
  
  
  register(event) {
    event.preventDefault();
    
    $("#errors *").remove();
    
    var fail=!verifyEmail();
    fail|=!verifyPresent("#passIn", "#passDiv");
    fail|=!verifyConfirmPassword();
    fail|=!verifyPresent("#forenameIn", "#forenameDiv");
    fail|=!verifyPresent("#surnameIn", "#surnameDiv");
    
    if (fail==false) {
      this.sendRegisterOrUpdate(false);
    }
  }
  
  emailRequired(event) {
    event.preventDefault();
    
    $("#errors *").remove();
    
    var fail=!verifyEmail();
    fail|=!verifyPresent("#forenameIn", "#forenameDiv");
    fail|=!verifyPresent("#surnameIn", "#surnameDiv");
    
    if (fail==false) {
      this.sendRegisterOrUpdate(true);
    }
  }
}