import {verifyPresent, verifyConfirmPassword} from "./utils";
import $ from "jquery";




export default class ChangePassword {
  constructor(app) {
    this.app = app;

    this.app.console.info("Change password");
    $( document ).ready(function() {
      $("#change-password-form").submit(this.changePassword.bind(this));
    }.bind(this));
  }
  
  changePasswordSuccess(responseData) {
    $("#btn-update-password").show();
    
    if (responseData.success!=true) {
      $("#errors").append("<div class=\"alert alert-danger alert-dismissible\" role=\"alert\"><button type=\"button\" class=\"close\" data-dismiss=\"alert\"><span aria-hidden=\"true\">&times;</span><span class=\"sr-only\">Close</span></button>" + responseData.message  + ".</div>");
    } else {
      $("#errors").append("<div class=\"alert alert-success alert-dismissible\" role=\"alert\"><button type=\"button\" class=\"close\" data-dismiss=\"alert\"><span aria-hidden=\"true\">&times;</span><span class=\"sr-only\">Close</span></button>Password was changed successfully.</div>");
    }
  }
  
  changePasswordFail() {
    $("#btn-update-password").show();
    $("#errors").append("<div class=\"alert alert-danger alert-dismissible\" role=\"alert\"><button type=\"button\" class=\"close\" data-dismiss=\"alert\"><span aria-hidden=\"true\">&times;</span><span class=\"sr-only\">Close</span></button>Sorry your password was rejected, please try again later.</div>");
  }
  
  sendChangePassword() {
    $("#btn-update-password").hide();
  
    this.app.post("changePasswordJSON.php", { "password": $("#passIn").val() }, this.changePasswordSuccess.bind(this), null, this.changePasswordFail.bind(this));
  }
  
  changePassword(event) {
    event.preventDefault();
    
    $("#errors *").remove();
    
    var fail=!verifyPresent("#passIn", "#passDiv");
    fail|=!verifyConfirmPassword();
    
    if (fail==false) {
      this.sendChangePassword();
    }
  }
}