import $ from "jquery";




export default class SessionFinanceAdmin {
  constructor(app) {
    this.app = app;
    this.app.console.info("Session Finance Admin");
    
    $( document ).ready(function() {
      for (var i=0; i<=this.app.categories; i++) {
        $("#categoryBody"+i).toggle();
        $("#categoryHeaderDown"+i).toggle();
        $("#categoryHeader"+i).click(i, this._toggleCategory.bind(this));
      }
    }.bind(this));
  }

  _toggleCategory(e) {
    $("#categoryBody"+e.data).toggle();
    $("#categoryHeaderUp"+e.data).toggle();
    $("#categoryHeaderDown"+e.data).toggle();
  }
}