import AutoNumeric from "autonumeric/dist/autoNumeric.min";
import $ from "jquery";
import {verifyPresent} from "./utils";




export default class UserFinanceAdmin {
  constructor(app) {
    this.app = app;
    this.userFinanceId=0;
    this.app.console.info("User Finance Admin");
    
    $( document ).ready(function() {
      $(".adjust-account-btn").click(this.adjustAccount.bind(this));
      $(".extend-grace-btn").click(this.giveGrace.bind(this));
      this.adjustAmountInAN = new AutoNumeric("#adjustAmountIn");
      $("#saveAdjustment").click(this.saveAdjustment.bind(this));
      $("#saveGrace").click(this.saveGrace.bind(this));
    }.bind(this));
  }
  
  saveSuccess(result) {
    if (result.success) {
      this.app.reloadPage();
    } else {
      $("#errors").append("<div class=\"alert alert-danger alert-dismissible\" role=\"alert\"><button type=\"button\" class=\"close\" data-dismiss=\"alert\"><span aria-hidden=\"true\">&times;</span><span class=\"sr-only\">Close</span></button>" +  result.message + ".</div>");
    }
  }
  
  saveFail() {
    $("#errors").append("<div class=\"alert alert-danger alert-dismissible\" role=\"alert\"><button type=\"button\" class=\"close\" data-dismiss=\"alert\"><span aria-hidden=\"true\">&times;</span><span class=\"sr-only\">Close</span></button> save failed, please try again.</div>");
  }
  
  saveGraceSuccess(result) {
    if (result.success) {
      this.app.reloadPage();
    } else {
      $("#errorsGrace").append("<div class=\"alert alert-danger alert-dismissible\" role=\"alert\"><button type=\"button\" class=\"close\" data-dismiss=\"alert\"><span aria-hidden=\"true\">&times;</span><span class=\"sr-only\">Close</span></button>" +  result.message + ".</div>");
    }
  }
  
  saveGraceFail() {
    $("#errorsGrace").append("<div class=\"alert alert-danger alert-dismissible\" role=\"alert\"><button type=\"button\" class=\"close\" data-dismiss=\"alert\"><span aria-hidden=\"true\">&times;</span><span class=\"sr-only\">Close</span></button> save failed, please try again.</div>");
  }
  
  sendSaveAdjustment() {
    var amount=Math.floor(parseFloat(this.adjustAmountInAN.getNumericString())*100);
    if ($("#changeTypeIn").val()=="FINE") {
      amount=-amount;
    }
    this.app.post("userFinanceAdminJSON.php", { "adjustAmount": amount, "desc": $("#descIn").val(), "changeType": $("#changeTypeIn").val(), "userId": this.userFinanceId }, this.saveSuccess.bind(this), null, this.saveFail.bind(this));
  }
  
  sendSaveGrace() {
    var newTime=new Date($("#graceAmountIn").val()).getTime();

    this.app.post("updateGraceJSON.php", { "newGracePeriod": newTime, "userId": this.userFinanceId }, this.saveGraceSuccess.bind(this), null, this.saveGraceFail.bind(this));
  }
  saveAdjustment(event) {
    event.preventDefault();
    
    $("#errors *").remove();
    
    var fail=!verifyPresent("#adjustAmountIn", "#adjustAmountDiv");
    fail|=!verifyPresent("#descIn", "#descDiv");
      
    if (fail==false) {
      this.sendSaveAdjustment();
    }
  }
  
  saveGrace(event) {
    event.preventDefault();
    
    $("#errorsGrace *").remove();
    
    var fail=!verifyPresent("#graceAmountIn", "#graceAmountDiv");
      
    if (fail==false) {
      this.sendSaveGrace();
    }
  }
  
  adjustAccount(event) {
    event.preventDefault();
    
    var id = parseInt($(event.target).parent().parent().find("td:nth-child(1)").text());
    this.userFinanceId=id;
    var name = $(event.target).parent().parent().find("td:nth-child(3)").text() + " " + $(event.target).parent().parent().find("td:nth-child(4)").text();
    $("#usersName").text(name);
    this.app.userAdmin.passwordChangeUserId=parseInt($(event.target).parent().parent().find(".id-cell").text());
    $("#userFinanceAdjust").modal("show");
  }
  
  giveGrace(event) {
    event.preventDefault();
    
    var id = parseInt($(event.target).parent().parent().find("td:nth-child(1)").text());
    this.userFinanceId=id;
    var name = $(event.target).parent().parent().find("td:nth-child(3)").text() + " " + $(event.target).parent().parent().find("td:nth-child(4)").text();
    $("#usersNameGrace").text(name);
    this.app.userAdmin.passwordChangeUserId=parseInt($(event.target).parent().parent().find(".id-cell").text());
    var existingGrace = $(event.target).parent().parent().find("td:nth-child(8)").text();
    if (existingGrace.substring(0, 2) != "20") {
      existingGrace = new Date(new Date().getTime() + (31*86400000)).toISOString();
      existingGrace = (existingGrace.substring(0, 22));
    } else {
      existingGrace = (existingGrace.substring(0, 19)+".00").replace(" ", "T");
    }
    $("#graceAmountIn").val(existingGrace);
    $("#giveGraceAdjust").modal("show");
  }
}