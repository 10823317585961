import $ from "jquery";
import {verifyPresent} from "./utils";




export default class EditSessionFrequency {
  constructor(app) {
    this.app = app;
    this.srroID = null;
    this.costIx = 0;
    this.app.console.info("Edit Session");
  
    $( document ).ready(function() {
      $("#session-frequency-form").submit(this.saveSessionFrequencySubmit.bind(this));
      if ($("#sessionFrequenciesIdIn").val() == "") {
        $("#deleteSessionFrequency").hide();
      }
      $("#confirmDeleteSessionFrequency").hide();
      $("#deleteSessionFrequency").click(this.deleteSessionFrequencyAskConfirm.bind(this));
      $("#confirmDeleteSessionFrequency").click(this.confirmDeleteSessionFrequency.bind(this));
    }.bind(this));
  }
  
  setEnabled(isEnabled) {
    if (isEnabled) {
      $("#dayOfWeekIn").removeAttr("disabled");
      $("#startTimeHourIn").removeAttr("disabled");
      $("#startTimeMinIn").removeAttr("disabled");
      $("#endTimeHourIn").removeAttr("disabled");
      $("#endTimeMinIn").removeAttr("disabled");
      $("#createHowManyWeeksInAdvanceIn").removeAttr("disabled");
      $("#validFromIn").removeAttr("disabled");
      $("#validToIn").removeAttr("disabled");
      $("#everyOtherWeekIn").removeAttr("disabled");
    } else {
      $("#dayOfWeekIn").attr("disabled", "disabled");
      $("#startTimeHourIn").attr("disabled", "disabled");
      $("#startTimeMinIn").attr("disabled", "disabled");
      $("#endTimeHourIn").attr("disabled", "disabled");
      $("#endTimeMinIn").attr("disabled", "disabled");
      $("#createHowManyWeeksInAdvanceIn").attr("disabled", "disabled");
      $("#validFromIn").attr("disabled", "disabled");
      $("#validToIn").attr("disabled", "disabled");
      $("#everyOtherWeekIn").attr("disabled", "disabled");
    }
  }
  
  sessionFrequencySuccess(responseData) {
    this.setEnabled(true);
    
    if (responseData.success!=true) {
      this.app.alert(responseData.message);
    } else {
      window.location = "editSession.php?sessionId=" + $("#sessionIdIn").val();
    }
  }
  
  sessionFrequencyFail() {
    this.setEnabled(true);
    this.app.alert("Save failed, please try again");
  }
  
  sendSaveUpdateSessionFrequency() {
    this.setEnabled(false);
    var everyOtherWeek=0;
    if ($("#everyOtherWeekIn").prop("checked")) {
      everyOtherWeek=1;
    }
    var data={ 
      "sessionId": $("#sessionIdIn").val(), 
      "sessionFrequenciesId": $("#sessionFrequenciesIdIn").val(), 
      "dayOfWeek": $("#dayOfWeekIn").val(), 
      "startTimeHour": $("#startTimeHourIn").val(), 
      "startTimeMin": $("#startTimeMinIn").val(), 
      "endTimeHour": $("#endTimeHourIn").val(), 
      "endTimeMin": $("#endTimeMinIn").val(), 
      "createHowManyWeeksInAdvance": $("#createHowManyWeeksInAdvanceIn").val(), 
      "validFrom": $("#validFromIn").val(),
      "validTo": $("#validToIn").val(),
      "everyOtherWeek": everyOtherWeek
    };
    this.app.post("upsertSessionFrequencyJSON.php", data, this.sessionFrequencySuccess.bind(this), null, this.sessionFrequencyFail.bind(this));
  }
  
  saveSessionFrequencySubmit(event) {
    event.preventDefault();
    
    var fail=!verifyPresent("#dayOfWeekIn", "#dayOfWeekDiv");
    fail|=!verifyPresent("#startTimeHourIn", "#startTimeHourDiv");
    fail|=!verifyPresent("#startTimeMinIn", "#startTimeMinDiv");
    fail|=!verifyPresent("#endTimeHourIn", "#endTimeHourDiv");
    fail|=!verifyPresent("#endTimeMinIn", "#endTimeMinDiv");
    fail|=!verifyPresent("#createHowManyWeeksInAdvanceIn", "#createHowManyWeeksInAdvanceDiv");
    fail|=!verifyPresent("#validFromIn", "#validFromDiv");
    fail|=!verifyPresent("#validToIn", "#validToDiv");
      
    if (fail==false) {
      this.sendSaveUpdateSessionFrequency();
    }
  }
  
  confirmDeleteSessionFrequency(event) {
    this.setEnabled(false);
    event.preventDefault();
    this.app.post("deleteSessionFrequencyJSON.php", { "sessionFrequenciesId": $("#sessionFrequenciesIdIn").val()  }, this.deleteSuccess.bind(this), null, this.deleteFail.bind(this));
  }
  
  deleteSessionFrequencyAskConfirm(event) {
    event.preventDefault();
    $("#deleteSessionFrequency").hide();
    $("#confirmDeleteSessionFrequency").show();
  }
  
  deleteSuccess(responseData) {
    this.setEnabled(true);
    if (responseData.success!=true) {
      this.app.alert(responseData.message);
    } else {
      window.location = "editSession.php?sessionId=" + $("#sessionIdIn").val();
    }
  }
  
  deleteFail() {
    this.setEnabled(true);
    this.app.alert("Delete failed, please try again");
  }
}